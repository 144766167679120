<template>
  <div class="homeBox">
    <div class="bannerBox">
      <div class="bannerContent block">
        <el-carousel height="690px" :interval="5000" arrow="always" direction="direction" :autoplay="false">
          <el-carousel-item v-for="(item, index) in carouselList" :key="index">
            <div class="carouselContent" :interval="1000" :arrow="true" :style="'background:'+ 'url('+item.bgSrc+') no-repeat center 100%;'">
              <div class="centerBox">
                <h1 v-if="item.subTitle" class="subDynamicTitle">{{item.subTitle}}</h1>
                <h1 v-if="item.title" class="dynamicTitle">{{item.title}}</h1>
                <p v-if="item.title" class="titleDes">{{item.titleDes1}}</p>
                <p v-if="item.titleDes1" class="titleDes">{{item.titleDes2}}</p>
                <p v-if="item.titleDes3" class="titleDes">{{item.titleDes3}}</p>
                <div v-if="item.saveBtn" class="saveBox">
                  <div class="saveBtn" @click="goDetailPage">查看详情</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item :key="123123123">
            <div class="carouselContent" style="background: #000">
              <video
                      class="video"
                      src="/img/kedu.mp4"
                      preload="auto"
                      x-webkit-airplay="allow"
                      x5-video-player-type="h5"
                      x5-video-player-fullscreen="true"
                      x5-video-orientation="portrait"
                      playsinline
                      webkit-playsinline
                      type="video/mp4"
                      controls="controls"
              >
              </video>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="homeInfoBox">
      <div class="homeInfoContent contentBox">
        <div class="topHome">未成年人学习和成长已离不开网络</div>
        <p class="topDes">对于21世纪后出生的未成年人，互联网运用不再是需要刻意适应和学习的新鲜技能，而是“与生俱来”的能力。人们逐渐意识到应该为未成年人提供良好的网络生态环境。但真正适宜未成年人网络需求、保障他们上网安全的网络生态环境建设始终存在重大障碍。</p>
        <div class="topList">
          <div class="topItem">
            <div class="itemRound">99.3<span class="unitSPan">%</span></div>
            <p class="itemDes">未成年人网络普及率高达99.2%</p>
          </div>
          <div class="topItem">
            <div class="itemRound">85<span class="unitSPan">%</span></div>
            <p class="itemDes">85%未成年人上网场所为家庭</p>
          </div>
          <div class="topItem">
            <div class="itemRound">84<span class="unitSPan">%</span></div>
            <p class="itemDes">84%未成年人在家上网课</p>
          </div>
          <div class="topItem">
            <div class="itemRound">50<span class="unitSPan">%</span></div>
            <p class="itemDes itemTop">50%未成年人学习遇困难时网络求助</p>
          </div>
          <div class="topItem">
            <div class="itemRound">10<span class="unitSPan">%</span></div>
            <p class="itemDes itemTop">未成年人网瘾发病率10%</p>
          </div>
          <div class="topItem">
            <div class="itemRound">8.9<span class="unitSPan">%</span></div>
            <p class="itemDes itemLast">8.9%未成年人网络交友数超过现实生活</p>
          </div>
        </div>
        <div class="bottomHome">互联网对未成年人的负面影响日益显现</div>
        <div class="bottomList">
          <div class="leftReport">
            <img class="leftImg" src="@/assets/images/home/leftReport.png">
            <p class="leftDes">不经识别、筛选、管控的网络内容，影响孩子世界观、专注度、独立性养成</p>
            <div class="leftBottom">
              <img class="nameImg" src="@/assets/images/home/reportName.png">
              <p class="leftName">中国未成年人互联网运营报告</p>
            </div>
          </div>
          <div class="middleReport">
            <div class="leftMiddle">
              <img src="@/assets/images/home/leftMiddle.png">
              </div>
            <div class="rightMiddle">
              <p class="middleTop">不良网络内容影响孩子世界观的形成</p>
              <p class="middleDes">网络里充斥着大量不适宜孩子的内容，每天都在肆意地诱惑、消磨、侵害着他们，未成年人很容易被新鲜奇特的内容吸引，导致迷失，不能正确客观地认识世界。</p>
               <div class="rightBottom">
                <img class="nameImg" src="@/assets/images/home/reportName.png">
                <p class="rightName">中国未成年人互联网运营报告</p>
              </div>
            </div>
          </div>
          <div class="rightReport">
            <div class="rightText">碎片阅读对孩子产生负面影响</div>
            <p class="rightDes">浅显、碎片化的网络内容不仅分散孩子的注意力，还难以培养孩子独立思考和深度思考能力，导致孩子学习成绩下降、产生厌学情绪等。</p>
            <div class="rightBottom">
              <img class="nameImg" src="@/assets/images/home/reportName.png">
              <p class="rightName">中国未成年人互联网运营报告</p>
            </div>
          </div>
        </div>
        <div class="bottomResource">数据引自《中国未成年人互联网运用报告（2020）》— 社会科学文献出版社</div>
      </div>
    </div>
    <!-- <div class="netWorkBox">
        <div class="networkItem">
          <div class="netWorkLeftImgBox">
            <img class="bigImg" src="@/assets/images/home/network1.png"> 
          </div>
          <div class="netWorkLabel">
            <p class="subtitle">上网对于今天的孩子来说，不仅是娱乐方式，也是他们获取知识、增长阅历、接触社会的重要渠道。</p>
            <p class="title">网络带给孩子的并不全是积极正面的影响</p>
            <div class="preLine"></div>
            <p class="textInfo">网络里充斥着大量不适宜孩子的内容，每天都在肆意地诱惑、消磨、侵害着他们，很多孩子因为沉迷于网络，导致注意力不集中、学习成绩下滑、智力下降、厌学、甚至精神抑郁。</p>
          </div>
        </div>
        <div class="networkItem">
          <div class="netWorkLabel">
            <p class="subtitle">对孩子负责，就必须要合理地引导孩子的上网行为，不能让孩子过早、过度依赖网络。</p>
            <p class="title">为孩子健康成长保驾护航，是恪度存在的唯一理由！</p>
            <div class="preLine"></div>
            <p class="textInfo">家长要尽早和孩子共同建立上网规则，学习时专心学习，娱乐要遵守时间约定，不主动接触网络上不健康的内容。建立规则同时，还需要借助像恪度这样可靠的工具来实施有效监督，否则，规则必然流于形式，好习惯也将化为泡影。</p>
          </div>
          <div class="netWorkRightImgBox">
            <img class="bigImg" src="@/assets/images/home/network2.png"> 
          </div>
        </div>
    </div> -->
    <div class="desiredInfoBox">
      <div class="desiredContent">
        <div class="desiredTop">
          <div class="desiredTopLeft">
              <p class="leftLabel">为孩子拥抱网络健康成长保驾护航</p>
              <p class="leftLabel">是恪度诞生的理由、发展的使命，是爱与责任</p>
              <p class="topDes">我们呼吁，家长要尽早和孩子共同建立上网规则，学习时专心学习，娱乐要遵守时间约定，不主动接触网络上不健康的内容。</p>
          </div>
          <div class="desiredTopRight">
            <img src="@/assets/images/home/desiredPhone.png">
          </div>
        </div>
        <div class="desiredBottom">
          <p class="bottomTitle">恪度理念</p>
          <div class="bottomInfo">
            <div class="infoItem">
              <img class="infoImg" src="@/assets/images/home/infoIcon.png">
              <p class="infoDes">我们致力于通过技术创新</p>
              <p class="infoDes">让每一个孩子拥抱网络、健康成长！</p>
              <p class="infoDes">让每一个家庭少一些争吵，多一些温情！</p>
              <p class="infoDes">让父母不再焦虑和担心！</p>
              <p class="infoDes">让孩子更加专注和自律！</p>
              <p class="infoDes">这是恪度人的梦想！</p>
            </div>
            <div class="infoItem">
              <img class="infoImg" src="@/assets/images/home/infoIcon.png">
              <p class="infoDes">这个梦想驱使我们每个人、每一天</p>
              <p class="infoDes">都怀揣着对孩子的热爱在用心地设计、研发、测试产品，</p>
              <p class="infoDes">坚信我们付出的每一分努力都将被孩子和父母感知。</p>      
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="societyInfoBox">
      <div class="societyContent">
        <h1 class="title">社会动态</h1>
        <div class="infoList">
          <div class="infoItem" v-for="(item, index) in list" :key="index" @click="goDetailPath(item)">
            <p class="imgBox"><img :src="item.coverImg"></p>
            <p class="itemTitle">{{item.title}}</p>
            <p class="itemDes">{{item.subTitle}}</p>
          </div>
        </div>
        <div class="queryAll" @click="queryAll()">查看全部</div>
      </div>
    </div>
    <!-- <div class="publishInfoBox">
      <img src="@/assets/images/home/publishInfoBg.png">
    </div> -->
  </div>
</template>
<script>
import {dynamicInfoData} from "@/mockData/dynamicData";
import bannerBg1 from '@/assets/images/home/bannerBg.png';
import {getArticleList} from '@/api/packageApi';
// import bannerBg2 from '@/assets/images/home/bannerBg2.png';
export default {
  name: "home",
  data() {
      return {
        carouselList: [
          {id: "0", subTitle: "恪度智能路由器",title: "学习更专心 上网更放心",  bgSrc: bannerBg1, titleDes1: "源头管控更彻底｜四种模式更灵活｜远程操控更便捷", saveBtn: true},
          // {id: "1", title: "", bgSrc: bannerBg2, titleDes1: "",titleDes2: "",titleDes3: "", saveBtn: false}
        ],
        list:[],
        societyList: []
      }
  },
  filters:{
    splitTit(val){
      if(val.toString().length>40){
        return val.substr(0,40)+'...';
      }else{
        return val;
      }
    },
  },
  mounted() {
    this.getArticleList();
    // this.getDataHandle();
  },
  methods: {
    goDetailPath(item) {
        this.$router.push(`/dynamicInfoDetail/${item.id}`);
    },
    queryAll(){
      this.$router.push(`/dynamicInfo`);
    },
    getArticleList(){
      let params = {
        type:509613297020416,
        page:1,
        limit:1000,
        pcMove:1,
        isPublish:1
      }
      getArticleList(params).then(res=>{
        if(res.returnCode === 1){
          this.list=[];
          res.returnDataList.data.map((o,i)=>{
            if (i<3){
              this.list.push(o)
            }
          })
        }
      })
    },
    getDataHandle(){
      if(dynamicInfoData){
        let list = dynamicInfoData.list || [];
        list.forEach((item, index)=>{
          if(index<3){
            this.societyList.push(item)
          }
        })
      }
    },
    goDetailPage(){
      this.$router.push(`/product`);
    }
  }
}
</script>
<style  lang="less"  scoped>
.homeBox{
  width: 100%;
  height: 100%;
}
.bannerBox{
  width: 100%;
  height: 690px;
}
.bannerBox .bannerContent{
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .carouselContent{
    width: 100%;
    height: 690px;
    .video{
      margin: 0 auto;
      display: block;
    }
    // background: url('../../assets/images/home/bannerBg.png') no-repeat center 100%;
    .centerBox{
      margin: 0 auto;
      width: 1440px;
      height: 690px;
      .subDynamicTitle{
        padding-top: 165px;
        padding-left: 110px;
        width: 100%;
        height: 215px;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3FBB9A;
        line-height: 50px;
        -webkit-text-stroke: 1px #49D4BF;
        text-stroke: 1px #49D4BF;
      }
      .dynamicTitle{
        padding-left: 110px;
        width: 100%;
        height: 92px;
        font-size: 66px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2BD5A8;
        line-height: 92px;
        -webkit-text-stroke: 1px #49D4BF;
        text-stroke: 1px #49D4BF;
        -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
      .titleDes{
        padding-left: 114px;
        width: 100%;
        line-height: 36px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC, MicrosoftYaHei;
        font-weight: 500;
        color: #3FBB9A;
      }
      .saveBox{
        display: flex;
        justify-content: flex-start;
        margin-top: 45px;
        padding-left:  113px;
        width: 100%; 
        .saveBtn{
          width: 180px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC, MicrosoftYaHei;
          font-weight: 500;
          color: #000000;
          letter-spacing:2px;
          background: #29EFBF;
          border-radius: 30px;
          cursor: pointer;
          &:hover{
            color: #180790; 
          }
          &:active{
            color: #180790;
            background: #15CDA0;
          }
        }
      }
    }
  }
}
/***  homeInfoBox **/
.homeInfoBox{
  margin: 0 auto;
  width: 100%;
  height: 1266px;
  background: url("../../assets/images/home/homeInfo.png") no-repeat center 100%;
  // background: #FAFBFE;
  .homeInfoContent{
    padding-top: 91px;
    height: 100%;
    
    .topHome{
      padding-left: 128px;
      width: 100%;
      height: 45px;
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
    }
    .topDes{
      padding-left: 128px;
      padding-right: 184px;
      margin-top: 23px;
      width: 100%;
      height: 60px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #646464;
      line-height: 30px;
    }
    .topList{
      display: flex;
      justify-content: space-between;
      padding-left: 128px;
      padding-right: 135px;
      padding-top: 37px;
      width: 100%;
      .topItem{
        padding-top: 32px;
        width: 170px;
        height: 224px;
        background: #FFFFFF;
        box-shadow: 2px 2px 12px 0px rgba(51, 9, 0, 0.08);
        border-radius: 16px;
        .itemRound{
          margin: 0 auto;
          width: 100px;
          height: 100px;
          line-height: 100px;
          background: #D8FFFC;
          border-radius: 50%;
          overflow: hidden;
          text-align: center;
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          .unitSPan{
            width: 16px;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;
          }
        }
        .itemDes{
          margin: 25px auto 0;
          width: 101px;
          height: 40px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
        .itemTop{
          width: 118px;
        }
        .itemLast{
          width: 128px;
        }
      }
    }
    .bottomHome{
      margin-top: 242px;
      padding-left: 128px;
      width: 100%;
      height: 45px;
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
    }
    .bottomList{
      display: flex;
      justify-content: space-between;
      margin-top: 34px;
      padding-left: 128px;
      padding-right: 102px;
      width: 100%;
      height: 341px;
      .leftReport{
        padding-top: 21px;
        width: 310px;
        height: 341px;
        background: #FFFFFF;
        box-shadow: 2px 2px 12px 0px rgba(51, 9, 0, 0.08);
        border-radius: 16px;
        .leftImg{
          margin-left: 20px;
          width: 270px;
          height: 120px;
          outline-style: none;
        }
        .leftDes{
          padding-left: 23px;
          padding-right: 19px;
          margin-top: 28px;
          width: 100%;
          height: 48px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .leftBottom{
          margin-top: 58px;
          padding-left: 23px;
          padding-right: 19px;
          display: flex;
          justify-content: flex-start;
          .nameImg{
            width: 40px;
            height: 40px;
          }
          .leftName{
            margin-left: 10px;
            width: 182px;
            height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1B1B1B;
            line-height: 40px;
          }
        }
      }
      .middleReport{
        display: flex;
        justify-content: space-between;
        width: 560px;
        height: 341px;
        background: #FFFFFF;
        box-shadow: 2px 2px 12px 0px rgba(51, 9, 0, 0.08);
        border-radius: 16px;
        .leftMiddle{
          padding-top: 20px;
          padding-left: 20px;
          width: 249px;
          height: 320px;
          img{
            width: 229px;
            height: 300px;
          }
        }
        .rightMiddle{
          padding-top: 32px;
          padding-right: 23px;
          width: 291px;
          height: 100%;
          .middleTop{
            width: 268px;
            height: 56px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
          }
          .middleDes{
            margin-top: 22px;
            width: 268px;
            height: 147px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
          .rightBottom{
            margin-top: 17px;
            display: flex;
            justify-content: flex-start;
            .nameImg{
              width: 40px;
              height: 40px;
            }
            .rightName{
              margin-left: 10px;
              width: 182px;
              height: 40px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #1B1B1B;
              line-height: 40px;
            }
          }
        }
      }
      .rightReport{
        padding-top: 31px;
        width: 310px;
        height: 341px;
        background: #FFFFFF;
        box-shadow: 2px 2px 12px 0px rgba(51, 9, 0, 0.08);
        border-radius: 16px;
        .rightText{
          padding-left: 23px;
          width: 100%;
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
        }
        .rightDes{
          padding-left: 23px;
          padding-right: 19px;
          margin-top: 51px;
          width: 100%;
          height: 131px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .rightBottom{
          margin-top: 38px;
          padding-left: 23px;
          padding-right: 19px;
          display: flex;
          justify-content: flex-start;
          .nameImg{
            width: 40px;
            height: 40px;
          }
          .rightName{
            margin-left: 10px;
            width: 182px;
            height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1B1B1B;
            line-height: 40px;
          }
        }
      }
    }
    .bottomResource{
      margin-top: 58px;
      padding-right: 41px;
      width: 100%;
      height: 30px;
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #D7D6D6;
      line-height: 30px;
    }
  }
}
/***  network **/
.netWorkBox{
  margin: 0 auto;
  padding: 11px 120px 124px;
  width: 1440px;
  height: 1266px;
  .networkItem{
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    width: 100%;
    height: 633px;
    .netWorkLeftImgBox{
      position: relative;
      width: 560px;
      height: 500px;
      .bigImg{
        width: 560px;
        height: 500px;
        outline-style: none;
      }
    }
    .netWorkRightImgBox{
      position: relative;
      width: 560px;
      height: 500px;
      .bigImg{
        width: 560px;
        height: 500px;
        outline-style: none;
      }
    }
    .netWorkLabel{
      width: 497px;
      height: 500px;
      padding-top: 22px;
      .subtitle{
        width: 100%;
        height: 44px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FD6F63;
        line-height: 22px;
        letter-spacing: 1px;
      }
      .title{
        margin-top: 20px;
        width: 496px;
        height: 100px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC, MicrosoftYaHei;
        font-weight: 600;
        color: #323232;
        line-height: 50px;
      }
      .preLine{
        margin-top: 29px;
        width: 147px;
        height: 4px;
        background: #0A6259;
      }
      .textInfo{
        margin-top: 19px;
        width: 458px;
        height: 116px;
        font-size: 18px;
        line-height: 29px;
        font-family: PingFangSC-Semibold, PingFang SC, MicrosoftYaHei;
        // font-weight: 400;
        color: #646464;
      }
    }
  }
}
/**恪度理念 */
.desiredInfoBox{
  width: 100%;
  height: 980px;
  .desiredContent{
    margin: 0 auto;
    padding-top: 66px;
    width: 1440px;
    height: 100%;
    background: url("../../assets/images/home/desiredInfoBg.png") no-repeat center 100%;
    .desiredTop{
        display: flex;
        justify-content: space-between;
        padding-left: 118px;
        padding-right: 140px;
        width: 100%;
        height: 448px;
      .desiredTopLeft{
        padding-top: 114px;
        width: 658px;
        height: 100%;
        .leftLabel{
          width: 658px;
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 45px;
        }
        .topDes{
          margin-top: 14px;
          width: 652px;
          height: 60px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #646464;
          line-height: 30px;
        }
      }
      .desiredTopRight{
        width: 345px;
        height: 448px;
        img{
          width: 345px;
          height: 448px; 
        }
      }
    }
    .desiredBottom{
      padding-left: 118px;
      padding-right: 140px;
      width: 100%;
      height: 466px;
      .bottomTitle{
        margin-top: -22px;
        width: 100%;
        height: 45px;
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 45px;
      } 
      .bottomInfo{
        margin-top: 95px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 295px;
        .infoItem{
          position: relative;
          padding-left: 48px;
          padding-top: 48px;
          width: 555px;
          height: 270px;
          background: #FFFFFF;
          box-shadow: 2px 2px 12px 0px rgba(51, 9, 0, 0.08);
          border-radius: 16px;
          .infoDes{
            width: 459px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #323232;
            line-height: 32px;
          }
          .infoImg{
            position: absolute;
            left: 48px;
            top: -25px;
            z-index: 99;
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}

/**社会动态 */
.societyInfoBox{
  width: 100%;
  min-height: 1005px;
  .societyContent{
    margin: 0 auto;
    padding-top: 72px;
    width: 1440px;
    height: 100%;
    .title{
      margin: 0 auto;
      padding-left: 121px;
      width: 100%;
      height: 45px;
      line-height: 45px;
      text-align: left;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .infoList{
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      padding: 0 121px;
      width: 100%;
      min-height: 530px;
      .infoItem{
        width: 335px;
        height: 100%;
        .imgBox{
          // padding: 24px;
          width: 335px;
          height: 352px;
          // background: #FFFFFF;
          // border: 1px solid #DDDDDD;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
            // background-color: #f5f5f5;
            object-fit: cover;
            outline-style: none;
          }
        }
        .itemTitle{
          margin-top: 32px;
          width: 100%;
          height: 56px;
          line-height: 28px;
          text-align: left;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          cursor: pointer;
        }
        .itemDes{
          margin-top: 12px;
          width: 100%;
          // height: 58px;
          line-height: 29px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #646464;
          word-wrap:break-word;
          word-break:normal; 
          cursor: pointer;
        }
      }
    }
    .queryAll{
      margin: 98px auto 93px;
      width: 180px;
      height: 60px;
      line-height: 56px;
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #65E1C3;
      border-radius: 36px;
      border: 2px solid #65E1C3;
      cursor: pointer;
    }
  }
}

.publishInfoBox{
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 205px;
  background-color: #D5FAF3;
  img{
    width: 100%;
    height: 100%;
    outline-style: none;
  }
}
</style>