<template>
   <div class="aboutUsBox">
    <div class="bannerBox">
      <div class="bannerContent">
        <h1 class="bannerTitle">关于我们</h1>
      </div>
    </div>
    <div class="aboutInfoBox">
      <div class="aboutInfoContent contentBox">
        <ul class="aboutTopList">
          <li class="aboutItem">
            <img class="aboutImg" src="@/assets/images/aboutUs/aboutImg1.png"/>
            <h5 class="title">使命</h5>
            <p class="aboutText textTop">让孩子拥抱网络</p>
            <p class="aboutText">健康成长</p>
          </li>
          <li class="aboutItem">
            <img class="aboutImg" src="@/assets/images/aboutUs/aboutImg2.png"/>
            <h5 class="title">愿景</h5>
            <p class="aboutText textTop">做值得托付的孩子健康</p>
            <p class="aboutText">上网的守护者</p>
          </li>
          <li class="aboutItem">
            <img class="aboutImg" src="@/assets/images/aboutUs/aboutImg3.png"/>
            <h5 class="title">价值观</h5>
            <p class="aboutText textTop">为了孩子</p>
            <p class="aboutText">做有爱的产品</p>
          </li>
        </ul>
        <div class="aboutInfo">
          <img class="desImg" src="@/assets/images/aboutUs/aboutInfo.png"/>
          <div class="desInfo">
            <h3 class="title">公司简介</h3>
            <p class="des">北京恪度科技有限公司成立于 2020 年 9 月，专注于为促进青少年健康使用网络，提升学习专注力和自律性提供技术解决方案。让孩子拥抱网络、健康成长是恪度的使命。</p>
            <p class="des">恪度是国内首款专为孩子设计的路由器。它根据孩子的网络使用习惯，分别设计了网课模式、作业模式、娱乐模式和断网模式。家长可配置各模式生效时间和应用模板，轻松管理孩子上网行为，帮助孩子学会合理使用网络，养成专注、自律的好习惯。</p>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "aboutUs",
  data() {
    return {
      
      
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDataHandle();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataHandle();
    },
    getDataHandle(){
      console.log("更新数据")
    }
  },
}
</script>
<style lang="less" scoped>
.aboutUsBox {
  width: 100%;
  height: 100%;
}
.bannerBox {
  width: 100%;
  height: 280px;
  background: rgba(213, 250, 243,0.52);
  .bannerContent {
    margin: 0 auto;
    width: 1440px;
    height: 100%;
    background: url("../../assets/images/aboutUs/topBg.png") no-repeat center 100%;
    .bannerTitle{
      padding-top: 120px;
      padding-left: 120px;
      width: 100%;
      height: 180px;
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #646262;
      line-height: 60px;
    }
  }
}

.aboutInfoBox{
  width: 100%;
  .aboutInfoContent {
    padding-top: 80px;
    .aboutTopList{
      display: flex;
      justify-content: space-between;
      padding-left: 208px;
      padding-right: 216px;
      width: 100%;
      height: 340px;
      .aboutItem{
        width: 220px;
        .aboutImg{
          margin-left: 52px;
          width: 116px;
          height: 116px;
          outline-style: none;
        }
        .title{
          margin-top: 28px;
          width: 100%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .aboutText{
          width: 100%;
          line-height: 26px;
          text-align: center;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        } 
        .textTop{
          margin-top: 14px;
        }
      }
    }
    .aboutInfo{
      display: flex;
      justify-content: space-between;
      padding-left: 180px;
      padding-right: 200px;
      width: 100%;
      padding-bottom: 81px;
      .desImg{
        width: 500px;
        height: 300px;
      }
      .desInfo{
        width: 500px;
        .title{
          margin-bottom: 33px;
          width: 500px;
          height: 52px;
          line-height: 52px;
          font-size: 40px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .des{
          padding-right: 20px;
          width: 500px;
          line-height: 32px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  } 
}

</style>