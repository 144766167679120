<template>
  <div class="viewPointDetailBox" :style="'min-height:' + dynamicHeight + 'px;'">
    <div class="bannerBox">
      <div class="bannerContent"></div>
    </div>
    <div class="serviceDetailBox">
      <div class="serviceContent contentBox">
        <div class="detailTitle">{{info.title}}</div>
        <div class="detailPoint">
          <img src="@/assets/images/viewPoint/pointDetail.png">  
          <span>恪度观点</span>
          <span class="time" v-if="info.publishTime">{{info.publishTime.split(" ")[0]}}</span>
        </div>
        <div class="breadcrumbBox">
          <span class="crumLabel labelLeft"  @click="goOtherPage('viewPoint')">恪度观点</span>
          <span class="crumLine"></span>
          <span class="crumLabel labelLeft">文章正文</span>
        </div>
        <div class="content">
          <p v-html="info.content"></p>
        </div>
      </div>
    </div>  
  </div>
</template>
<script>
// import {viewPointData} from "@/mockData/dynamicData";
// import { ElMessage } from 'element-plus';
import {getArticleById} from '@/api/packageApi';
export default {
  name: "viewPointDetail",
  data() {
      return {   
        dynamicHeight: window.innerHeight - 80 - 285,
        activeId: null,
        title: "不同年龄段的孩子，应该如何管理上网行为？",
        time: "2021-04-30",
        detailData: {},
        info:{},
      }
  },
  mounted(){
    this.activeId = this.$route.params.activeId;
    this.getDataHandle();
  },
  methods: {
    getDataHandle(){
      let params = {
        id:this.activeId
      }
      getArticleById(params).then(res=>{
        if(res.returnCode == 1){
          this.info=res.returnDataList;
        }
      })
    },
    goOtherPage(path){
      this.$router.push(`/${path}`)
    }
  }
}
</script>
<style  lang="less"  scoped>
.serviceSupportDetailBox{
  width: 100%;
}
.bannerBox{
  width: 100%;
  height: 80px;
  background-color: #EEFFFC;
  .bannerContent{
    width: 1440px;
    height: 100%;
  }
}
.viewPointDetailBox{
  width: 100%;
  // height: 570px;
  .serviceContent {
    padding: 40px 135px  0 178px;
    height: 100%;
    .detailTitle{
      width: 100%;
      height: 60px;
      line-height: 60px;  
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #646262;
    }
    .detailPoint{
      display: flex;
      justify-content: flex-start;
      margin-top: 28px;
      width: 100%;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 400;
      color: #333333;
      img{
        margin-right: 15px;
        width: 28px;
        height: 28px;
        outline-style: none;
      }
      .time{
        margin-left: 22px;
        color: #999999;
      }
    }
    .breadcrumbBox{
      margin-top: 20px;
      padding: 6px 0;
      width: 100%;
      height: 32px;
      line-height: 20px;
      .crumLabel{
        margin-right: 20px;
        display: inline-block;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        // font-weight: 500;
        color: #888888;
        cursor: pointer;
      }
      .labelLeft{
        margin-left: 10px;
      }
      .crumLine{
          width:0;
          height:0;
          overflow:hidden;
          vertical-align: middle;
          font-size: 0;     /*是因为, 虽然宽高度为0, 但在IE6下会具有默认的 */
          line-height: 0;  /* 字体大小和行高, 导致盒子呈现被撑开的长矩形 */
          border-width:10px;
          border-style: dashed dashed dashed solid;/*IE6下, 设置余下三条边的border-style为dashed,即可达到透明的效果*/
          border-color: transparent transparent transparent #28BFAF ;
        }
    }
    .content{
      padding-top: 40px;
      padding-bottom: 40px;
      p{
        width: 1000px;
        line-height: 32px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        // font-weight: 400;
        color: #333333;
        span{
          margin-top: 30px;
          text-indent:2em;
        }
      }
    }
    
  }
}    
</style>