<template>
   <div class="dynamicInfoBox">
    <div class="bannerBox">
      <div class="bannerContent contentBox">
        <div class="dynamicTitle">社会动态</div>
        <div class="breadcrumbBox">
          <span class="crumLabel" @click="goOtherPage('home')">首页</span>
          <span class="crumLine"></span>
          <span class="crumLabel labelLeft"  @click="goOtherPage('dynamicInfo')">社会动态</span>
        </div>
      </div>
    </div>
    <div class="viewListBox">
      <div class="viewListContent contentBox">
        <ul class="viewList">
          <li class="viewItem"  v-for="(item, index) in viewList" :key="index" @click="goDetailPage(item)">
            <img class="viewImg" :src="item.coverImg"/>
            <div class="viewLabel">
              <h4 class="title">{{item.title}}</h4>
              <p v-if="item.titleInfo" class="des">{{item.titleInfo}}</p>
              <p class="des">{{item.subTitle}}</p>
              <div class="pointBox">
                <div class="pointLeft">
                  来源：
                  <span class="sourceName">{{item.source}}</span>
                  </div>
                <div class="pointRight">
                  <img src="@/assets/images/viewPoint/timeImg.png"/>
                  {{item.publishTime.split(" ")[0]}}
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="paginationBox">
          <el-pagination v-if="total> 10"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :next-text="前往"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSiae"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {dynamicInfoData} from "@/mockData/dynamicData";
import {getArticleList} from '@/api/packageApi';

export default {
  name: "viewPoint",
  data() {
    return {
      currentPage: 1,
      total: 10,
      pageSize:15,
      viewList: []
    }
  },
  mounted() {
    this.getDataHandle();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDataHandle();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataHandle();
    },
    goDetailPage(item){
      // this.$router.push("/dynamicInfoDetail/"+ item.id)
      window.open(`/dynamicInfoDetail/${item.id}`, "_blank");
    },
    goOtherPage(path){
      this.$router.push(`/${path}`) 
    },
    getDataHandle(){
      let params = {
        type:509613297020416,
        page:1,
        limit:1000,
        pcMove:1,
        isPublish:1
      }
      getArticleList(params).then(res=>{
        if(res.returnCode === 1){
          this.viewList=[];
          this.viewList=res.returnDataList.data
          // this.total = res.returnDataList.count || 0;
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.dynamicInfoBox {
  width: 100%;
  height: 100%;
}
.bannerBox {
  width: 100%;
  height: 280px;
  background-color: rgba(	213, 250, 243,.52);
  .bannerContent {
    margin: 0 auto;
    width: 1440px;
    height: 100%;
    background: url('../../assets/images/common/bannerBg.png') no-repeat center 100%;
    .dynamicTitle{
      padding:120px 120px 0;
      width: 100%;
      height: 180px;
      line-height: 60px;
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #646262;
    }
    .breadcrumbBox{
      margin-top: 24px;
      padding: 6px 120px;
      width: 100%;
      height: 32px;
      line-height: 20px;
      .crumLabel{
        margin-right: 20px;
        display: inline-block;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #888888;
        cursor: pointer;
      }
      .labelLeft{
        margin-left: 10px;
      }
      .crumLine{
          width:0;
          height:0;
          overflow:hidden;
          vertical-align: middle;
          font-size: 0;     /*是因为, 虽然宽高度为0, 但在IE6下会具有默认的 */
          line-height: 0;  /* 字体大小和行高, 导致盒子呈现被撑开的长矩形 */
          border-width:10px;
          border-style: dashed dashed dashed solid;/*IE6下, 设置余下三条边的border-style为dashed,即可达到透明的效果*/
          border-color: transparent transparent transparent #28BFAF ;
        }
    }
  }
}
.viewListBox{
  width: 100%;
  .viewListContent {
    padding: 40px 120px 0;
    .viewList{
      width: 100%;
      .viewItem{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 250px;
        margin-bottom: 60px;
        .viewImg{
          width: 400px;
          height: 250px;
          outline-style: none;
        }
        .viewLabel{
          position: relative;
          width: 760px;
          height: 250px;
          .title{
            width: 770px;
            // height: 44px;
            line-height: 44px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333;
            cursor: pointer;
            &:hover{
              color: #65E1C3;
            }
          }
          .des{
            margin-top: 20px;
            width: 760px;
            // height: 72px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
          .pointBox{
            display: flex;
            justify-content: space-between;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
            width: 760px;
            height: 40px;
            .pointLeft{
              display: flex;
              justify-content: flex-start;
              width: 50%;
              line-height: 40px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              span{
                margin-left: 4px;
              }
              .sourceName{
                color: #999999;
              }
            }
            .pointRight{  
              display: flex;
              justify-content: flex-end;
              width: 50%;
              text-align: right;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              img{
                margin-right: 7px;
                width: 12px;
                height: 12px;
                outline-style: none;
              }
            }
          }
        }
      }
    }
    .paginationBox{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-bottom: 63px;
    }
  } 
}

</style>