<template>
   <div class="viewPointBox">
    <div class="bannerBox">
      <div class="bannerContent">
        <h1 class="bannerTitle">恪度观点</h1>
        <!-- <p class="bannerDes">我们希望，每一个孩子都可以享受到健康、绿色的网络环境；</p>
        <p class="bannerDes">我们希望，每一个家庭都能愉快积极的成长；</p>
        <p class="bannerDes">我们希望，通过我们的努力，让教育轻松一点，让家长信任孩子多一点。</p> -->
      </div>
    </div>
    <div class="viewListBox">
      <div class="viewListContent contentBox">
        <ul class="viewList">
          <li class="viewItem"  v-for="(item, index) in viewList" :key="index" @click="goDetailPage(item)">
            <img class="viewImg" :src="item.coverImg"/>
            <div class="viewLabel">
              <h4 class="title">{{item.title}}</h4>
              <p class="des">{{item.subTitle}}</p>
              <div class="pointBox">
                <div class="pointLeft">
                  <img src="@/assets/images/viewPoint/pointImg.png"/>
                  恪度观点
                </div>
                <div class="pointRight">
                  <img src="@/assets/images/viewPoint/timeImg.png"/>
                  {{item.publishTime.split(" ")[0]}}
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="paginationBox">
          <el-pagination v-if="total > 10"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :next-text="前往"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSiae"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {viewPointData} from "@/mockData/dynamicData";
import {getArticleList} from '@/api/packageApi';
export default {
  name: "viewPoint",
  data() {
    return {
      currentPage: 1,
      total: 0,
      pageSize:10,
      viewList: []
    }
  },
  mounted() {
    this.getDataHandle();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDataHandle();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataHandle();
    },
    goDetailPage(item){
      // this.$router.push("/viewPointDetail/"+ item.id)
      window.open(`/viewPointDetail/${item.id}`, "_blank");
    },
    getDataHandle(){
      let params = {
        type:509613775072768,
        page:1,
        limit:1000,
        pcMove:1,
        isPublish:1
      }
      getArticleList(params).then(res=>{
        if(res.returnCode === 1){
          this.viewList=[];
          this.viewList=res.returnDataList.data
          // this.total = 12 || 0;
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.viewPointBox {
  width: 100%;
  height: 100%;
}
.bannerBox {
  width: 100%;
  height: 360px;
  background-color: rgba(	213, 250, 243,.52);
  .bannerContent {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: #D5FAF3 url('../../assets/images/viewPoint/topBg.png') no-repeat center 100%;
    .bannerTitle{
      margin: 0 auto;
      padding-top: 120px;
      padding-left: 120px;
      width: 1440px;
      height: 180px;
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #646262;
      line-height: 60px;
    }
    .bannerDes{
      margin-top: -8px;
      padding-left: 372px;
      width: 100%;
      line-height: 60px;
      font-size: 32px;
      font-family: -Regular, ;
      font-weight: 400;
      color: #6BE3C5;
    }
  }
}

.viewListBox{
  width: 100%;
  .viewListContent {
    padding: 40px 120px 0;
    .viewList{
      width: 100%;
      .viewItem{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 250px;
        margin-bottom: 60px;
        .viewImg{
          width: 400px;
          height: 250px;
          outline-style: none;
          cursor: pointer;
        }
        .viewLabel{
          position: relative;
          width: 760px;
          height: 250px;
          .title{
            width: 770px;
            // height: 44px;
            line-height: 44px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333;
            cursor: pointer;
            &:hover{
              color: #65E1C3;
            }
          }
          .des{
            margin-top: 20px;
            width: 760px;
            height: 72px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
          .pointBox{
            display: flex;
            justify-content: space-between;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
            width: 760px;
            height: 40px;
            .pointLeft{
              display: flex;
              justify-content: flex-start;
              width: 50%;
              line-height: 40px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              img{
                margin-right: 10px;
                width: 40px;
                height: 40px;
                outline-style: none;
              }
            }
            .pointRight{  
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: 50%;
              line-height: 40px;
              text-align: right;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              img{
                margin-right: 7px;
                width: 12px;
                height: 12px;
                outline-style: none;
              }
            }
          }
        }
      }
    }
    .paginationBox{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-bottom: 63px;
    }
  } 
}

</style>