import axios from 'axios';

export const getServiceLeftListHandle = params => {
    return axios.get("/base/dictionaryInfo/getOrderDictionaryInfoList", {params}).then(res => res.data)
}
export const getServiceRightListHandle = params => {
    return axios.get("/base/questionInfo/getQuestionInfoList", {params}).then(res => res.data)
}
export const getServicDetailHandle = params => {
    return axios.get("/base/questionInfo/getQuestionInfoById", {params}).then(res => res.data)
}
export const saveQuestionHandle = params => {
    return axios.post("/base/questionAsk/insertQuestionAsk", params).then(res => res.data)
}
export const getArticleList = params => {
    return axios.get("/base/article/getArticleList", {params}).then(res => res.data)
}
export const getArticleById = params => {
    return axios.get("/base/article/getArticleById", {params}).then(res => res.data)
}

