<template>
  <div class="brandStoryBox">
    <div class="bannerBox">
      <div class="bannerContent">
        <h1 class="bannerTitle">品牌故事</h1>
      </div>
    </div>
    <div class="mainDetail">
      <div class="detailContent contentBox">
        <div class="detailTop">
          <div class="leftBox">
            <img class="createUserImg" src="@/assets/images/brandStory/createUserImg.png"/>
            <div class="creaseUserName">
              <span>恪度创始人：</span>
              <img class="name" src="@/assets/images/brandStory/name.png"/>
            </div>
            <div class="userDes">毕业于清华大学，深耕IT行业20余年，连续创业者，两个孩子的父亲</div>
          </div>
          <div class="rightBox">
            <div class="rightTitle">恪度的来历</div>
            <p class="detailDes">恪度的诞生源于一位父亲的焦虑。</p>
            <p class="detailDes">2020年初，一场突如其来的疫情，将大人和孩子都圈在了家里。大人远程办公，孩子们上网课。休息时上网打游戏、看看视频。不知从什么时候，我突然发现随着孩子在网络上花的时间越来越多，他的身上出现了各种问题：上网课时不专心，一个弹屏广告就可以轻易地把他的注意力带走；拿手机上网查资料时，忍不住刷抖音；题目稍有不会做就拍题看答案；游戏结束时间到了，还是放不下手中的手机，经常是要靠我去争抢。那段时间，因为上网问题，我和孩子发生了很多次冲突，变得越来越焦虑。</p>
            <p class="detailDes">是孩子不够自律吗？其实冷静下来想一下，并不能全怪孩子。那些互联网公司利用人性的弱点，将内容经营发挥到极致。大人尚且无法抵抗，更何况身心发育尚不成熟的孩子。作为家长，我有责任帮助孩子抵御这些诱惑，健康使用网络。</p>
            <p class="detailDes">意识到这一点后，我马上开始采取行动。首先和孩子沟通。其实孩子心里很清楚什么才是正确的行为，所以我顺利地和孩子约定了上网规则：每天允许有一定的上网娱乐时间，但是学习时要专心学习，娱乐要遵守时间约定，不主动接触网上不健康的内容等。我把规则打印下来，让他签字，贴在墙上。</p>
            <p class="detailDes">起初，我以为这样就解决问题了，但没过多久，就发现订好的规则形同虚设。因为面临诱惑的时候，孩子还是会管不住自己，虽然他知道这么做是不对的。</p>
            <p class="detailDes">看来仅仅订好规则远远不够，还需要一个管理工具来保证规则被遵守。于是我从各种途径去寻找这样一个工具。然而在考察了市场上形形色色，各种软、硬件方案后，我很失望，它们有的只是把上网防沉迷作为宣传上的噱头、有的存在较大的局限性、有的操作极其繁琐……</p>
            <p class="detailDes">这番市场调查下来也并非一无所获，在我的头脑里逐渐形成了这个工具应有的样子。孩子的健康成长是大事，我决心自己来做一款这样的产品，帮助自己，也帮助其他和我有同样需求的人！</p>
            <p class="detailDes">这就是恪度的来历！</p>
          </div>
        </div>
        <div class="detailBottom">
          <div class="meanInfo">
            <div class="meanTitle">“恪度”的含义</div>
            <p class="meanDes">“恪度”是我们产品的名字，它承载着恪度人对这个产品的全部期望。恪：恭敬、谨慎。度：法度、规章。希望在恪度的帮助下，孩子能拥有恪守承诺，尊重规则的优秀品格，养成自律、专注的良好习惯。</p>
          </div>
          <img class="meanImg" src="@/assets/images/brandStory/meanImg.png"/>  
        </div>
      </div>
    </div>  
  </div>
</template>
<script>
export default {
  name: "brandStory"
}
</script>
<style lang="less" scoped>
.brandStoryBox{
  width: 100%;
  height: 100%;
}
.bannerBox{
  width: 100%;
  height: 360px;
  background-color: rgba(	213, 250, 243,.52);
  .bannerContent{
    margin: 0 auto;
    width: 1440px;
    height: 100%;
    background: url('../../assets/images/brandStory/bannerBg.png') no-repeat center 100%;
    .bannerTitle{
      padding-top: 120px;
      padding-left: 120px;
      width: 100%;
      height: 180px;
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #646262;
      line-height: 60px;
    }
  }
}
.mainDetail{
  width: 100%;
  height: 1331px;
  .detailContent {  
    padding-top: 50px;
    height: 100%;
    .detailTop{
      display: flex;
      justify-content: space-between;
      padding-left: 120px;
      padding-right: 155px;
      width: 100%;
      height: 815px;
      .leftBox{
        width: 420px;
        height: 100%;
        .createUserImg{
          width: 420px;
          height: 620px;
          outline-style: none;
        }
        .creaseUserName{
          display: flex;
          margin-top: 13px;
          width: 100%;
          height: 58px;
          line-height: 58px;
          span{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          .name{
            width: 77px;
            height: 58px;
          }
        }
        .userDes{
          margin-top: 4px;
          width: 100%;
          height: 40px;
          line-height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
      .rightBox{
        width: 680px;
        height: 100%;
        .rightTitle{
          width: 100%;
          padding-left: 80px;
          line-height: 44px;
          height: 72px;
          font-size: 44px;
          font-family: PingFangSC-Semibold, PingFang SC;
          background-image: url('../../assets/images/brandStory/titleBg.png');
          background-repeat: no-repeat;
          background-size: 100px 76px;
          background-position: left;
          font-weight: 600;
          color: #000000;
        }  
        .detailDes{
          padding-right: 20px;
          width: 680px;
          line-height: 32px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .detailBottom{
      display: flex;
      justify-content: space-between;
      padding-top: 70px;
      padding-left: 120px;
      padding-right: 174px;
      padding-bottom: 76px;
      width: 100%;
      height: 451px;
      .meanInfo{
        width: 480px;
        height: 300px;
        .meanTitle{
          width: 100%;
          height: 60px;
          line-height: 60px;
          font-size: 44px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .meanDes{
          margin-top: 20px;
          width: 100%;
          height: 194px;
          line-height: 32px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
      .meanImg{
        width: 500px;
        height: 300px;
        outline-style: none;
      }
    }
    
  }
}   
</style>