import { createRouter, createWebHistory} from 'vue-router';
import home from '@/PC/home/home';
import product from '@/PC/product/product';
import viewPoint from '@/PC/viewPoint/viewPoint';
import viewPointDetail from '@/PC/viewPoint/viewPointDetail';
import brandStory from '@/PC/brandStory/brandStory';
import aboutUs from '@/PC/aboutUs/aboutUs';
import serviceSupport from '@/PC/serviceSupport/serviceSupport';
import serviceSupportDetail from '@/PC/serviceSupport/serviceSupportDetail';
import feedBack from '@/PC/feedBack/feedBack';
import dynamicInfo from '@/PC/dynamicInfo/dynamicInfo';
import dynamicInfoDetail from '@/PC/dynamicInfo/dynamicInfoDetail';


const router = createRouter({
  history: createWebHistory(),// 生产环境需配置nigix转发
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: home
    },
    {
      path: '/product',
      name: 'product',
      component: product
    }, 
    {
      path: '/viewPoint',
      name: 'viewPoint',
      component: viewPoint
    },
    {
      path: '/viewPointDetail/:activeId',
      name: 'viewPointDetail',
      component: viewPointDetail
    },
    {
      path: '/brandStory',
      name: 'brandStory',
      component: brandStory
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: aboutUs
    },
    {
      path: '/serviceSupport',
      name: 'serviceSupport',
      component: serviceSupport
    },
    {
      path: '/serviceSupportDetail/:activeId',
      name: 'serviceSupportDetail',
      component: serviceSupportDetail
    },
    {
      path: '/feedBack',
      name: 'feedBack',
      component: feedBack
    },
    {
      path: '/dynamicInfo',
      name: 'dynamicInfo',
      component: dynamicInfo
    },
    {
      path: '/dynamicInfoDetail/:activeId',
      name: 'dynamicInfoDetail',
      component: dynamicInfoDetail
    },
    {
      path: '/*',
      redirect: '/home'
    }
  ],
  
})

export default router;
