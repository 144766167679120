<template>
  <div class="headerBox">
    <div class="headerContent">
      <div class="leftItem">
        <img class="headerIcon" @click="go" src="@/assets/images/common/headerLeft.png">
      </div>
      <div class="rightItem">
        <span class="menuLabel" v-for="(item, index) in headerList" :key="index" :style="'width:'+ item.width + 'px;'" @click="goOtherPath(item.path)">
          <span :class="{'active': item.id === activeIndex }">{{item.name}}</span>
          </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'header',
    watch: {
      '$route': 'watchRoute'
    },
    data() {
      return {
        headerList: [
          { id: 0, name: "恪度", path: "home", width: 60,},
          { id: 1, name: "产品", path: "product", width: 60,},
          { id: 2, name: "购买", path: "jd", width: 60,},
          { id: 3, name: "恪度观点", path: "viewPoint", width: 92 },
          { id: 4, name: "品牌故事", path: "brandStory", width: 92 },
          { id: 5, name: "关于我们", path: "aboutUs", width: 92 },
        ],
        activeIndex: 0
      }
    },
    mounted() {
      let path = window.location.pathname;
      let headerItem = this.headerList.find(item => path.includes(item.path));
      headerItem ? this.activeIndex = headerItem.id : this.activeIndex = 999;
    },
    methods: {
      go(){
        this.$router.push("/home")
      },
      watchRoute() {
        let path = window.location.pathname;
        let headerItem = this.headerList.find(item => path.includes(item.path));
        headerItem ? this.activeIndex = headerItem.id : this.activeIndex = 999;
      },
      goOtherPath(path) {
        this.activeIndex = this.headerList.find(item => item.path === path).id;
        if (path === "jd") {//打开jd首页
          window.open("https://item.taobao.com/item.htm?spm=a230r.1.14.16.13fa31ecq22WUr&id=645651415513&ns=1&abbucket=2#detail", "_blank");
        } else {
          this.$router.push(`/${path}`);
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .headerBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    width: 100%;
    height: 80px;
  }

  .headerContent {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-left: 120px;
    padding-right: 100px;
    width: 1440px;
    height: 100%;
    .leftItem {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      padding: 23px 0;
      height: 100%;
      line-height: 50px;
      .headerIcon {
        width: 264px;
        height: 33px;
        outline-style: none;
      }
      .leftLine {
        margin: 15px 10px 0 20px;
        display: inline-block;
        width: 1px;
        height: 20px;
        background: #D8D8D8;
        border: 1px solid #979797;
      }
      .sloganIcon {
        margin-top: 15px;
        width: 162px;
        height: 20px;
        outline-style: none;
      }
    }
  }
  .rightItem {
    display: flex;
    justify-content: flex-end;
    padding: 28px 0;
    width: 540px;
    height: 100%;
    line-height: 24px;
    .menuLabel{
      margin-left: 12px;
      display: inline-block;
      span{
        padding: 2px 14px;
        border-radius: 28px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        &:hover {  
          color: #180790;
          // background-color: #29EFBF;
        }
      }
      .active {
        color: #180790;
        background-color: #29EFBF;
      }
    }   
  }
</style>