<template>
  <div class="serviceSupportBox">
    <div class="bannerBox">
      <div class="bannerContent">
        <div class="dynamicTitle">服务与支持</div>
        <div class="breadcrumbBox">
          <span class="crumLabel" @click="goOtherPage('home')">首页</span>
          <span class="crumLine"></span>
          <span class="crumLabel labelLeft"  @click="goOtherPage('serviceSupport')">服务与支持</span>
        </div>
      </div>
    </div>
    <div class="serviceBox">
      <div class="serviceContent contentBox">
        <div class="leftDirectory">
          <div class="leftItem" v-for="(item, index) in leftOptions" :key="index" @click="changeLeft(item)">
            <span class="leftLine" :class="{'activeLine': item.id == activeIndex }"></span>
            <span class="leftName" :class="{'activeName': item.id == activeIndex }">{{item.dictionaryName}}</span>
          </div>
        </div>
        <div class="rightDirectory">
          <ul class="rightList">
            <li class="rightItem" v-for="(item, index) in rightList" :key="index" @click="goDetailPath(item)" :class="{'active': item.id == activeId }">{{item.title}}</li>
            <li class="rightItem" v-if="rightList.length == 0">暂无数据</li>
          </ul>
          <div class="paginationBox">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :next-text="前往"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSiae"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
        </div>
        </div>
      </div>
    </div>  
  </div>
</template>
<script>
import {getServiceLeftListHandle, getServiceRightListHandle} from '@/api/packageApi';
export default {
  name: "serviceSupport",
  data() {
      return {
        activeIndex: 0,
        leftOptions: [],
        rightList: [],
        activeId: null,
        currentPage: 1,
        total: 0,
        pageSize:10,
      }
  },
  mounted(){
    this.getLeftDataHandle();
  },
  methods: {
    goOtherPage(path){
      this.$router.push(`/${path}`) 
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getRightDataHandle();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getRightDataHandle();
    },
    changeLeft(item) {
      this.activeIndex = item.id;
      this.total = 0;
      this.currentPage = 1;
      this.activeId = null;
      this.getRightDataHandle();
    },
    goDetailPath(item){
      this.activeId = item.id;
      // this.$router.push("/serviceSupportDetail/"+ this.activeId);
      window.open(`/serviceSupportDetail/${this.activeId}`, "_blank");
    },
    getLeftDataHandle(){
      let params = {
        pDictionaryNo: 3,
        page: 1,
        pageSize: 100
      }
      getServiceLeftListHandle(params).then(res=>{
        if(res.returnCode === 1){
          let list = res.returnDataList && res.returnDataList.data || [];
          if(list.length > 0){
            this.activeIndex = list[0].id;
            this.leftOptions = list;
            this.getRightDataHandle();
          }
        }
      })
    },
    getRightDataHandle(){
      let questionType = this.leftOptions.find(item => item.id == this.activeIndex).dictionaryNo
      let params = {
        questionType,
        page: this.currentPage,
        pageSize: this.pageSize
      }
      getServiceRightListHandle(params).then(res=>{
        if(res.returnCode === 1){
          let list = res.returnDataList && res.returnDataList.data || [];
          if(list.length > 0){
            this.rightList = list;
            this.total = res.returnDataList.count
          }else{
            this.rightList = [];
            this.total = 0
          }
        }
      })
    },
  }
}
</script>
<style  lang="less"  scoped>
.serviceSupportBox{
  width: 100%;
  height: 100%;
}
.bannerBox{
  width: 100%;
  height: 280px;
  background-color: rgba(	213, 250, 243,.52);
  .bannerContent{
    margin: 0 auto;
    width: 1440px;
    height: 100%;
    background: url('../../assets/images/common/bannerBg.png') no-repeat center 100%;
    .dynamicTitle{
      padding:120px 120px 0;
      width: 100%;
      height: 180px;
      line-height: 60px;
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #646262;
    }
    .breadcrumbBox{
      margin-top: 24px;
      padding: 6px 120px;
      width: 100%;
      height: 32px;
      line-height: 20px;
      .crumLabel{
        margin-right: 20px;
        display: inline-block;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #888888;
        cursor: pointer;
      }
      .labelLeft{
        margin-left: 10px;
      }
      .crumLine{
          width:0;
          height:0;
          overflow:hidden;
          vertical-align: middle;
          font-size: 0;     /*是因为, 虽然宽高度为0, 但在IE6下会具有默认的 */
          line-height: 0;  /* 字体大小和行高, 导致盒子呈现被撑开的长矩形 */
          border-width:10px;
          border-style: dashed dashed dashed solid;/*IE6下, 设置余下三条边的border-style为dashed,即可达到透明的效果*/
          border-color: transparent transparent transparent #28BFAF ;
        }
    }
  }
}
.serviceBox{
  width: 100%;
  height: 570px;
  .serviceContent {
    display: flex;
    justify-content: flex-start;
    padding: 20px 120px 28px;
    height: 100%;
    .leftDirectory{
      width: 280px;
      height: 500px;
      background: #FFFFFF;
      .leftItem{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
        .leftLine{
          display: inline-block;
          width: 4px;
          height: 60px;     
        }
        .leftName{
          margin-left: 26px;
          display: inline-block;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .activeLine{
          background: #65E1C3;
        }
        .activeName{
          color: #28BFAF;
        }
      }
    }
    .rightDirectory{
      margin-left: 20px;
      width: 900px;
      height: 522px;
      background: #FFFFFF;
      .rightList{
        margin: 0;
        padding-top: 13px;
        width: 100%;
        height: 400px;
        li{
          width: 900px;
          // height: 42px;
          line-height: 42px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
          &:hover{
            color: #43A1FC;
          }
        }
        .active{
          color: #43A1FC;
        }
      }
      .paginationBox{
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;
        padding-left: 104px;
        width: 100%;
      } 
    }
    
  }
}    
</style>