<template>
  <div class="mainBox commonBg">
    <app-header/>
    <div class="contentBox">
      <router-view/>
    </div>
    <app-footer/>
  </div>
</template>

<script>
import header from '@/components/header';
import footer from '@/components/footer';
export default {
  name: 'App',
  components: {
    "app-header": header,
    "app-footer": footer,

  }
}
</script>

<style  scoped>

#app {
  font-family: PingFangSC-Semibold, PingFang SC, MicrosoftYaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.mainBox{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.contentBox{
  /* padding-top: 60px;
  padding-bottom: 404px; */
  width: 100%;
  /* overflow-y: auto; */
}

</style>
