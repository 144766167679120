import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'; 
import './assets/css/reset.less';
import './assets/css/common.less';
import './assets/css/global.less';


const app = createApp(App);
router.afterEach(() => {
  window.scrollTo(0,0);
})
app.use(ElementPlus, { locale });
app.use(router);
app.mount('#app');
