<template>
  <div class="feedBackBox" :style="'min-height:' + dynamicHeight + 'px;'">
    <div class="bannerBox">
      <div class="bannerContent"></div>
    </div>
    <div class="feedBox">
      <div class="feedContent contentBox">
        <div class="feedTitle">提交反馈，您的建议是我们的动力</div>
        <div class="formContent">
          <div class="conditionBox">
            <div class="leftPhone">
              <label class="phoneLabel">您的联系方式</label>
              <el-input class="phoneIn" v-model="phone" maxlength="11" placeholder="请输入您的联系方式"></el-input>
            </div>
            <div class="rightType">
              <label class="typeLabel">问题类型</label>
              <el-select class="typeSelect" v-model="typeValue" placeholder="请选择问题类型">
                <el-option v-for="item in typeOptions" :key="item.id" :label="item.dictionaryName" :value="item.dictionaryName"></el-option>
              </el-select>
            </div>
          </div>
          <div class="desBox">
            <label class="desLabel">问题描述</label>
            <el-input class="desText" type="textarea" v-model="questionValue" :resize="none" :rows="12" placeholder="说说您的问题"></el-input>
          </div>
        </div>
        <div class="saveBox">
          <div class="saveBtn" @click="saveBtn">提交反馈</div>
        </div>
      </div>
    </div>  
  </div>
</template>
<script>
import { ElMessage } from 'element-plus';
import {getServiceLeftListHandle, saveQuestionHandle} from '@/api/packageApi';
export default {
  name: "feedBack",
  data() {
      return {
        dynamicHeight: window.innerHeight - 80 - 285,
        phone: "",
        typeValue: "",
        questionValue: "",
        typeOptions: []
      }
  },
  mounted(){
    this.getTypeDataHandle();
  },
  methods: {
    filterPhone(value){
      if(value.substr(0,2) === '86' ){
          return  value.substr(2)
      }else if(value.substr(0,3) === '+86'){
          return  value.substr(3)
      }else{
          return value
      }
    },
    getTypeDataHandle(){
      let params = {
        pDictionaryNo: 2,
        page: 1,
        pageSize: 100
      }
      getServiceLeftListHandle(params).then(res=>{
        if(res.returnCode === 1){
          let list = res.returnDataList && res.returnDataList.data || [];
          if(list.length > 0){
            this.typeOptions = list;
          }
        }
      })
    },
    saveBtn(){
      let myreg = /^[0-9]{11}$/;
      if (!this.phone) {
        return ElMessage({
          message: '手机号不能为空!',
          center: true,
          type: 'error'
        });
      } else if (!myreg.test(this.filterPhone(this.phone))) {
        return  ElMessage({
          message: '请输入正确的手机号!',
          center: true,
          type: 'error'
        });
      }
      if(this.phone.length< 11){
        return  ElMessage({
          message: '请输入正确的手机号!',
          center: true,
          type: 'error'
        });
      }
      if (!this.typeValue) {
        return ElMessage({
          message: '请选择问题类型!',
          center: true,
          type: 'error'
        });
      }
      if (!this.questionValue) {
        return ElMessage({
          message: '请输入问题描述!',
          center: true,
          type: 'error'
        });
      }
      let questionType = this.typeOptions.find(item=> item.dictionaryName == this.typeValue).id;
      let params = {
        mobile: this.filterPhone(this.phone),
        questionType: questionType,
        questionTypeName: this.typeValue,
        content: this.questionValue,
        sendSiteId: 3
      }
      saveQuestionHandle(params).then(res=>{
        if(res.returnCode === 1){
          this.sucessConfirm();
        }else{
          this.errorConfirm();
        }
      })
    },
    sucessConfirm(){
      let _self = this;
      this.$alert('保存成功！', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          _self.phone = "";
          _self.typeValue= "";
          _self.questionValue = "";
          _self.$router.push("/home")
        }
      });
    },
    errorConfirm(){
      this.$alert('提交失败！', '提示', {
        confirmButtonText: '重新提交',
        callback: () => {
          console.log("")
        }
      });
    }
  }
}
</script>
<style  lang="less"  scoped>
.feedBackBox{
  width: 100%;
}
.bannerBox{
  width: 100%;
  height: 80px;
  background-color: #EEFFFC;
  .bannerContent{
    width: 1440px;
    height: 100%;
  }
}
.feedBox{
  width: 100%;
  height: 615px;
  .feedContent {
    padding-top: 40px;
    height: 100%;
    .feedTitle{
      padding: 0 120px;
      width: 100%;
      line-height: 60px;
      height: 60px;
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #646262;
    }
    .formContent{
      padding-left:  220px;
      padding-right: 233px;
      width: 100%;
      height: 324px;
      .conditionBox{
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        .leftPhone{
          width: 390px;
          .phoneLabel{
            margin-right: 20px;
            width: 100px;
            line-height: 40px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .phoneIn{
            width: 270px;
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            color: #999999;
            border-radius: 2px;
            // border: 1px solid #D8D8D8;
            .el-input__inner{
              height: 38px;
              line-height: 38px;
              border: none;
            }
          }
        }
        .rightType{
          margin-left: 80px;
          width: 360px;
          .typeLabel{
            margin-right: 20px;
            width: 64px;
            line-height: 40px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .typeSelect{
            width: 270px;
            height: 40px;
            border-radius: 2px;
            // border: 1px solid #D8D8D8;
          }
        }
      }
      .desBox{
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        width: 100%;
        height: 40px;
        line-height: 40px;  
        .desLabel{
          margin-right: 18px;
          width: 100px;
          height: 254px;
          line-height: 36px;
          text-align: right;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
        .desText{
          width: 883px;
          height: 254px;
          line-height: 36px;  
          border-radius: 2px;
          // border: 1px solid #D8D8D8;
          .el-textarea__inner{
            border: none;
            border-radius: none;
            height: 100%;
            line-height: 36px;
             font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            color: #999999;
          }
        }
      }
    }
    .saveBox{
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      padding-left:  220px;
      padding-right: 233px;
      width: 100%; 
      .saveBtn{
        width: 220px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        background: #65E1C3;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
}    
</style>