<template>
  <div class="serviceSupportDetailBox" :style="'min-height:' + dynamicHeight + 'px;'">
    <div class="bannerBox">
      <div class="bannerContent"></div>
    </div>
    <div class="serviceDetailBox">
      <div class="serviceContent contentBox">
        <div class="detailTitle">{{detailData.title}}</div>
        <div class="breadcrumbBox">
          <span class="crumLabel" @click="goOtherPage('home')">首页</span>
          <span class="crumLine"></span>
          <span class="crumLabel labelLeft"  @click="goOtherPage('serviceSupport')">服务与支持</span>
          <span class="crumLine"></span>
          <span class="crumLabel labelLeft">文章正文</span>
        </div>
        <div class="content">
          <p v-html="detailData.content"></p>
        </div>
      </div>
    </div>  
  </div>
</template>
<script>
import {getServicDetailHandle} from '@/api/packageApi';
export default {
  name: "serviceSupportDetail",
  data() {
      return {   
        dynamicHeight: window.innerHeight - 80 - 285,
        activeId: null,
        title: "如何重置路由器（恢复出厂设置）",
        detailData: {}
      }
  },
  mounted(){
    this.activeId = this.$route.params.activeId;
    this.getDataHandle();
  },
  methods: {
    getDataHandle(){
      let params = {
        id: this.activeId,
      }
      getServicDetailHandle(params).then(res=>{
        if(res.returnCode === 1){
          this.detailData = res.returnDataList || {};
        }
      })
    },
    goOtherPage(path){
      this.$router.push(`/${path}`)
    }
  }
}
</script>
<style  lang="less"  scoped>
.serviceSupportDetailBox{
  width: 100%;
}
.bannerBox{
  width: 100%;
  height: 80px;
  background-color: #EEFFFC;
  .bannerContent{
    width: 1440px;
    height: 100%;
  }
}
.serviceDetailBox{
  width: 100%;
  // height: 570px;
  .serviceContent {
    padding: 30px 178px 0 135px ;
    height: 100%;
    .detailTitle{
      width: 100%;
      height: 60px;
      line-height: 60px;  
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #646262;
    }
    .breadcrumbBox{
      margin-top: 20px;
      padding: 6px 0;
      width: 100%;
      height: 32px;
      line-height: 20px;
      .crumLabel{
        margin-right: 20px;
        display: inline-block;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #888888;
        cursor: pointer;
      }
      .labelLeft{
        margin-left: 10px;
      }
      .crumLine{
          width:0;
          height:0;
          overflow:hidden;
          vertical-align: middle;
          font-size: 0;     /*是因为, 虽然宽高度为0, 但在IE6下会具有默认的 */
          line-height: 0;  /* 字体大小和行高, 导致盒子呈现被撑开的长矩形 */
          border-width:10px;
          border-style: dashed dashed dashed solid;/*IE6下, 设置余下三条边的border-style为dashed,即可达到透明的效果*/
          border-color: transparent transparent transparent #28BFAF ;
        }
    }
    .content{
      padding: 24px 303px 0 85px;
      height: 100%;
    }
    
  }
}    
</style>