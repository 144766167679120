<template>
    <div class="productBox">
    <div class="bannerBox">
      <div class="bannerContent"></div>
      <!-- <div class="bannerAppBox">
        <div class="bannerApp"></div>
      </div> -->
    </div>
    <!-- <div class="routerInfoBox">
      <div class="routerContent contentBox">
        <h1 class="routerTitle">恪度 - 专为孩子设计的路由器</h1>
        <p class="routerDes workBreak">
          “恪度”是北京恪度科技有限公司于2021年推出的首款专为孩子设计的路由器。它支持网课、作业、娱乐，断网四种模式，可有效管理近万种应用和软件。家长可远程操作，配置时间计划，简单方便。
        </p>
      </div>
    </div> -->
    <!-- <div class="productBg"></div> -->
    <div class="modelTypeBox">
      <div class="modelTypeContent contentBox">
        <h2 class="typeTitle">四种上网模式</h2>
        <h4 class="subTypeTitle">按需切换，让孩子专时、专用、更专心</h4>
        <ul class="typeList">
          <li class="typeItem" v-for="(item, index) in typeList" :key="index">
            <img class="itemIcon" :src="item.imgUrl" :style="'width:'+item.width+'px;'">
            <p class="itemTitle">{{item.title}}</p>
            <p class="itemDes workBreak">{{item.des}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="checkHistoryBox">
      <div class="historyContent contentBox">
        <ul class="historyList">
          <li class="historyItem historyBottom">
            <div class="historyLabel">
              <h4 class="labelTitle">查看上网记录</h4>
              <h5 class="subTitle">多一个了解孩子的途径</h5>
              <p class="labelDes">开启这个功能，可以看到孩子使用了哪些APP，访问了哪些网站，从一个侧面了解青春期孩子的最新变化。为了不侵犯孩子的隐私，我们只提供孩子访问的应用和网站名称，不提供访问的具体内容。</p>
            </div>
            <img class="historyImg" src='@/assets/images/product/history1.png'>
          </li>
          <li class="historyItem planBottom">
            <img class="historyImg" src='@/assets/images/product/history2.png'>
            <div class="historyLabel">
              <h4 class="labelTitle">设置时间计划</h4>
              <h5 class="subTitle">帮助孩子建立时间观念</h5>
              <p class="labelDes" style="margin-top: 0px; width: 656px;">使用恪度的时间计划模块，可以对上网课、写作业、娱乐各个时间段合理安排。娱乐时间结束，网络自动切换到学习模式，不用再和孩子争抢手机。每天晚上定时断网，让孩子没有机会半夜偷偷上网，影响睡眠。</p>
            </div>
          </li>
          <li class="historyItem">
            <div class="historyLabel">
              <h4 class="labelTitle">内容精细管理</h4>
              <p class="labelDes" style="margin-top: 27px; width: 656px;">不仅可以管理APP，而且还可以管理APP里的具体内容。比如，在作业模式下使用百度，只可以使用搜索功能、百度百科、文库，但不能看百度游戏、影视、小说等栏目，也不能通过百度打开任何娱乐类网站。</p>
            </div>
            <img class="historyImg" src='@/assets/images/product/history3.png'>
          </li>
        </ul>
        <!-- <div class="controlInfo">
          <h3 class="controlTitle">远程操控</h3>
          <p class="controlDes">家长在办公室也能管理家里的网络，即使是在暑假也不用担心孩子在网络上彻底放飞自我。</p>
        </div> -->
      </div>
    </div>
    <div class="remoteBg">
      <p class="remoteTitle">远程操控</p>
      <p class="remoteDes">家长在办公室也能管理家里的网络，即使是在暑假也不用担心孩子在网络上彻底放飞自我。</p>
    </div>
    <div class="supportBox nowBox">
      <div class="supportContent contentBox">
        <div class="leftImg"></div>
        <div class="rightSupport">
          <h3 class="supportTitle">支持各类上网设备</h3>
          <p class="supportDes">可以管理电脑、手机、iPad，智能手表、智能电视等各种类型、各种型号需要上网的设备。</p>
        </div>
      </div>
    </div>
    <div class="supportBox nowBox" style="margin-top:0; background:#fff;">
      <div class="supportContent contentBox">
        <div class="leftSupport">
          <h3 class="supportTitle">网课加速</h3>
          <p class="supportDes">智能识别网课，优先传输，让孩子上课网速更流畅。</p>
        </div>
        <div class="rightImg"></div>
      </div>
    </div>
    <div class="supportBox nowBox"  style="margin-top:0;">
      <div class="supportContent contentBox" style="height: 614px;">
        <div class="produceInfoImg"></div>
        <div class="rightInfo">
          <h3 class="supportTitle">主副机配合</h3>
          <h3 class="supportTitle">轻松覆盖全屋无死角</h3>
          <p class="supportDes">一个主机可与多个副机相连。无论多大面积，不惧墙多、壁厚，Wi-Fi信号覆盖无死角。主副机同样高配置，保证连接更稳定，网速更快。</p>
        </div>
      </div>
    </div>
    <!-- <div class="speedBg"></div> -->
    <!-- <div class="supportBox">
      <div class="supportContent contentBox">
        <h3 class="supportTitle introduceTitle">128MB超大内存 </h3>
        <p class="introduceDes">128MB超大内存</p>
        <p class="introduceDes">管理海量教育类、娱乐类应用和网站</p>
        <div class="introduceImg"></div>
        <h3 class="bottomTitle bottomTop">主副机配合</h3>
        <h3 class="bottomTitle">轻松覆盖全屋无死角 </h3>
        <p class="bottomDes">一个主机可与多个副机相连。无论多大面积，不惧墙多、壁厚，Wi-Fi信号覆盖无死角。主辅机同样高配置，保证连接更稳定，网速更快。</p>
        <div class="supportBottomImg"></div>       
      </div>
    </div> -->
    <div class="configBox">
      <div class="configContent contentBox">
        <h3 class="configTitle">硬件配置</h3>
        <div class="configLine"></div>
        <ul class="paramList">
          <li class="paramItem"  v-for="(item, index) in hardwareList" :key="index">
            <span class="leftParam">{{item.leftParam}}</span>
            <span class="rightParam">{{item.rightParams}}</span>
          </li>
        </ul>
        <h3 class="configTitle paramListTop">软件参数</h3>
        <div class="configLine"></div>
        <ul class="paramList">
          <li class="paramItem"  v-for="(item, index) in softList" :key="index">
            <span class="leftParam">{{item.leftParam}}</span>
            <span class="rightParam">{{item.rightParams}}</span>
          </li>
        </ul>
        <h3 class="configTitle paramListTop">产品清单</h3>
        <div class="configLine"></div>
        <ul class="paramList">
          <li class="paramItem"  v-for="(item, index) in productList" :key="index">
            <span class="leftParam">{{item.leftParam}}</span>
            <span class="rightParam">{{item.rightParams}}</span>
          </li>
        </ul>
        <h3 class="configTitle paramListTop">工作环境</h3>
        <div class="configLine"></div>
        <ul class="paramList">
          <li class="paramItem"  v-for="(item, index) in workList" :key="index">
            <span class="leftParam">{{item.leftParam}}</span>
            <span class="rightParam">{{item.rightParams}}</span>
          </li>
        </ul>
        <h3 class="configTitle paramListTop">产品资质</h3>
        <div class="configLine"></div>
        <ul class="paramList">
          <li class="paramItem"  v-for="(item, index) in certification" :key="index">
            <span class="leftParam" style="width:700px;">{{item.leftParam}}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- TODO? 应该是有背景图的 -->
    <!-- <div class="publishInfoBox">
      <img src="@/assets/images/home/publishInfoBg.png">
    </div> -->
      <el-backtop ></el-backtop>

    </div>

</template>
<script>
// import { getMockDataAjaxHandle } from "@/api/packageApi";
import modeType1 from '@/assets/images/product/modeType1.png';
import modeType2 from '@/assets/images/product/modeType2.png';
import modeType3 from '@/assets/images/product/modeType3.png';
import modeType4 from '@/assets/images/product/modeType4.png';
export default {
  name: "product",
  data() {
      return {
        typeList: [
          {id: "0", title: "网课模式", des: "确保孩子在上课时间不能访问任何其它网络内容，也不会被弹屏广告干扰，是帮助孩子专心上课的最佳助手。", imgUrl: modeType1, width: 68},
          {id: "1", title: "作业模式", des: "自动拦截网络上绝大部分娱乐内容；还可根据需要随时添加、删除拍题、翻译等学习工具类软件，既能帮助孩子学习，又不会让孩子对它们形成依赖。", imgUrl: modeType2, width: 86},
          {id: "2", title: "娱乐模式", des: "轻松管理几千个娱乐类APP和网站，还可以自行添加黑名单网址。避免孩子过早接触和他年龄不符的网络内容。为他打造一个专属绿色网络环境。", imgUrl: modeType3, width: 76},
          {id: "4", title: "断网模式", des: "让孩子睡个好觉，第二天精力充沛去上学。", imgUrl: modeType4, width: 74},
        ],
        hardwareList: [
          {id: "0", leftParam: "产品颜色", rightParams: "白色"},
          {id: "1", leftParam: "处理器", rightParams: "MT7621A"},
          {id: "2", leftParam: "ROM", rightParams: "128MB"},
          {id: "3", leftParam: "产品内存", rightParams: "128MB"},
          {id: "4", leftParam: "2.4G Wi-Fi", rightParams: "2*2（支持IEEE 802.11n协议，速率可达300Mbps）"},
          {id: "5", leftParam: "5G Wi-Fi", rightParams: "2*2（支持IEEE 802.11ac协议，速率可达867Mbps）"},
          {id: "6", leftParam: "产品天线", rightParams: "2.4G和5G都是5dbi天线2根"},
          {id: "7", leftParam: "产品散热", rightParams: "自然散热"},
          {id: "8", leftParam: "整机接口", rightParams: "3个10/100/1000M自适应LAN口， 1个10/100/1000M自适应WAN口"},
          {id: "9", leftParam: "LED指示灯", rightParams: "2个电源灯，网络指示灯"},
          {id: "10", leftParam: "系统重置按键", rightParams: "1个"},
          {id: "11", leftParam: "电源输入接口", rightParams: "1个"},
          {id: "12", leftParam: "保修信息", rightParams: "整机保修1年"},
        ],
        softList: [
          {id: "0", leftParam: "操作系统", rightParams: "基于OpenWRT深度定制的智能路由器操作系统 KDWIFI ROM"},
          {id: "1", leftParam: "无线安全", rightParams: "WPA-PSK/WPA-PSK2加密，无线访问控制(黑白名单) ,智能防蹭网"},
          {id: "2", leftParam: "管理应用", rightParams: "支持WEB，Android，微信小程序"},
        ],
        productList:[
          {id: "0", leftParam: "路由器主机", rightParams: "1台"},
          {id: "1", leftParam: "说明书", rightParams: "1份"},
          {id: "2", leftParam: "适配器", rightParams: "1个"},
          {id: "4", leftParam: "网线", rightParams: "1根"},
        ],
        workList:[
          {id: "0", leftParam: "工作环境温度", rightParams: "0～40度"},
          {id: "1", leftParam: "工作湿度", rightParams: "10%～90%RH（不凝结）"},
          {id: "2", leftParam: "存储温度", rightParams: "-40～70度"},
          {id: "4", leftParam: "存储湿度", rightParams: "5%～90%RH（不凝结）"},
        ],
        certification:[
          {id: "0", leftParam: "无线电发射设备型号核准证 CMIIT ID: 2021AP5639", rightParams: "0～40度"},
          {id: "1", leftParam: "中国国家强制性产品认证证书 2021011608386881", rightParams: "10%～90%RH（不凝结）"},
        ]
      }
  },
  mounted(){
    // const _self = this;
    //  let halfWidth = window.innerWidth/2;
    //  console.log('halfWidth---', halfWidth)
    //  _self.appleft = halfWidth - 505;
    // window.onresize = ()=>{
    //   let halfWidth = window.innerWidth/2;
    //   _self.appleft = halfWidth - 505;
    // }
  },
  methods: {
    getData() {
      // let config = {
      //   url: "/mockData/runningLog.json",
      //   params: null,
      // };
      // getMockDataAjaxHandle(config).then((res) => {
      //   if (res.code === 20000) {
      //   } else {
      //   }
      // });
    },
  },
};
</script>
<style  lang="less" scoped>
.productBox {
  width: 100%;
  height: 100%;
}
.bannerBox {
  width: 100%;
  height: 687px;
  background-color: #ffffff;
  // opacity: 0.8;
  .bannerContent {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background:  url('../../assets/images/product/bannerBg.png') no-repeat center 100%;
  }
  .bannerAppBox{
    position: absolute;
    left: 0;
    right: 0;
    top: 348px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
    .bannerApp{
      width: 180px;
      height: 180px;
      background-image :url('../../assets/images/common/appDown.png');
      background-repeat: no-repeat;
      background-size: 180px;
    }
  }
}
@media screen and (width:1920px) {
  .bannerApp{
    margin-right: 818px;
  }
}

@media screen and (max-width:1440px) {
  .bannerApp{
    margin-right: 400px;
  }
}
/**路由描述 */
.routerInfoBox {
  width: 100%;
  height: 295px;
  .routerContent {
    padding-top: 74px;
    height: 100%;
    .routerTitle {
      margin: 0 auto;
      width: 860px;
      height: 52px;
      line-height: 52px;
      font-size: 60px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .routerDes {
      margin: 43px auto 0;
      width: 860px;
      height: 72px;
      line-height: 36px;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
/**大背景图 或图片 */
.productBg {
  margin: 0 auto;
  width: 1440px;
  height: 700px;
  background: url('../../assets/images/product/productTop.png') no-repeat center 100%;
}
/**模式 */
.modelTypeBox {
  width: 100%;
  .modelTypeContent {
    padding-top: 45px;
    .typeTitle {
      margin: 0 auto;;
      width: 860px;
      height: 52px;
      text-align: center;
      font-size: 60px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 52px;
    }
    .subTypeTitle {
      margin: 42px auto 0;
      width: 860px;
      height: 29px;
      text-align: center;
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 29px;
    }
    .typeList{
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 78px 135px 0;
      .typeItem{
        padding-top: 28px;
        width: 270px;
        height: 450px;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        .itemIcon{
          margin-left: 30px;
          width: 98px !important;
          height: 96px !important;
          outline-style: none;
        }
        .itemTitle{
          margin-top: 56px;
          padding: 0 32px;
          width: 100%;
          height: 32px;
          line-height: 32px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .itemDes{
          margin-top: 20px;
          padding: 0 32px;
          width: 100%;
          line-height: 32px;
          height: 128px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }

    }
  }
}
/**上网记录 */
.checkHistoryBox{
  margin-top: 120px;
  width: 100%;
  background-color: #EEFFFC;
  .historyContent{
    padding-top: 120px;
    .historyList{
      margin: 0;
      padding:0 170px 120px 120px;
      .historyItem{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 413px;
        .historyLabel{
          width: 700px;
          height: 100%;
          .labelTitle{
            width: 100%;
            line-height: 52px;
            height: 52px;
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .subTitle{
            margin-top: 19px;
            width: 100%;
            height: 63px;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 63px;
          }
          .labelDes{
            margin-top: 35px;
            width: 570px;
            line-height: 36px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .labelNotice{
            margin-top: 71px;
            width: 700px;
            height: 72px;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 36px;
          }
        }
        .historyImg{
          width: 280px;
          outline-style: none;
        }
      }
      .historyBottom{
        margin-bottom: 129px;
      }
      .planBottom{
        margin-bottom: 155px;
      }
    }
    .controlInfo{
      margin-top: 201px;
      width: 100%;
      .controlTitle{
        margin: 0 auto;
        width: 860px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        font-size: 60px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      } 
      .controlDes{
        margin: 34px auto ;
        width: 860px;
        height: 88px;
        line-height: 44px;
        text-align: center;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.remoteBg {
  position: relative;
  margin: 107px auto 0;
  width: 1200px;
  height: 600px;
  background: url("../../assets/images/product/remoteBg.png") no-repeat center 100%;
  .remoteTitle{
    position: absolute;
    right: 263px;
    top: 182px;
    z-index: 99;
    width: 154px;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 50px;
  }
  .remoteDes{
    position: absolute;
    right: 63px;
    top: 254px;
    z-index: 99;
    width: 354px;
    height: 108px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
  }
}
.speedBg {
  margin: 107px auto 0;
  width: 1440px;
  height: 700px;
  background: url("../../assets/images/product/speedBg.png") no-repeat center 100%;
}
/**支持 */
.supportBox{
  margin-top: 120px;
  width: 100%;
  background-color: #EEFFFC;
  .supportContent{ 
    padding-top: 120px;
    height: 720px;
    .leftImg{
      float: left;
      margin-left: 120px;
      width: 490px;
      height: 480px;
      background: url("../../assets/images/product/supportBg.png") no-repeat center 100%;
    }
    .produceInfoImg{
      float: left;
      margin-left: 120px;
      width: 648px;
      height: 374px;
      background: url("../../assets/images/product/produceInfo.png") no-repeat center 100%;
    }
    .rightSupport{
      float: right;
      margin-right: 114px;
      width: 546px;
      padding-top: 247px;
      .supportTitle{
        width: 298px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
      }
      .supportDes{
        margin-top: 23px;
        width: 546px;
        height: 72px;
        font-size: 24px;
        text-align: left;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
      }
    }
    .rightInfo{
      float: right;
      margin-right: 109px;
      width: 516px;
      padding-top: 78px;
      .supportTitle{
        width: 334px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
      }
      .supportDes{
        margin-top: 10px;
        width: 516px;
        height: 108px;
        font-size: 24px;
        text-align: left;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
      }
    }
    
    .leftSupport{
      float: left;
      margin-left: 200px;
      padding-top: 168px;
      width: 298px;
      .supportTitle{
        width: 288px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
      }
      .supportDes{
        margin-top: 21px;
        width: 298px;
        height: 72px;
        text-align: left;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
      }
    }
    .rightImg{
      float: right;
      margin-right: 175px;
      width: 597px;
      height: 481px;
      background: url("../../assets/images/product/introduceBg.png") no-repeat center 100%;
    }
    
    .otherTitle{
      margin-top: 254px;
    }
    .introduceTitle{
      margin-bottom: 34px;
    }
    .bottomTitle{
      margin: 0 auto;
      width: 860px;
      height: 84px;
      line-height: 84px;
       text-align: center;
      font-size: 60px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .bottomTop{
      margin-top: 264px;
    }
    .supportDes{
      margin: 34px auto 0;
      width: 860px;
      height: 88px;
      text-align: center;
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 44px;
    }
    .introduceDes{
      margin: 0 auto;
      width: 860px;
      height: 88px;
      line-height: 44px;
      text-align: center;
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .bottomDes{
      margin: 25px auto 0;
      width: 1000px;
      height: 88px;
      line-height: 44px;
      text-align: center;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .supportImg{
      margin: 107px auto 0;
      width: 800px;
      height: 784px;
      background: url("../../assets/images/product/supportBg.png") no-repeat center 100%;
    }
    .introduceImg{
      margin: 138px auto 0;
      width: 1178px;
      height: 916px;
      background: url("../../assets/images/product/introduceBg.png") no-repeat center 100%;
      // border: 1px dotted red;
    }
    .supportBottomImg{
      margin: 99px auto 0;
      width: 999px;
      height: 575px;
      background: url("../../assets/images/product/supportBottomBg.png") no-repeat center 100%;
      // border: 1px dotted red;
    }
  }
}    
/**配置 */
.configBox{
  width: 100%;
  .configContent{
    padding: 120px 120px 0;
    .configTitle{
      width: 860px;
      height: 84px;
      line-height: 84px;
      font-size: 44px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .configLine{
      margin-top: 14px;
      width: 1200px;
      height: 1px;
      background: #D8D8D8;
      border: 1px solid #979797;
    }
    .paramList{
      padding-top: 40px;
      padding-bottom: 64px;
      margin: 0;
      width: 100%;  
      .paramItem{
        width: 100%;
        line-height: 56px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        // font-weight: 400;
        color: #333333;
        .leftParam{
          display: inline-block;
          width: 168px;
        }
        .rightParam{
          margin-left: 100px;
          width: 900px;
        }
      }
    }
    .heightParamList{
      padding-top: 54px;
    }
    .paramListTop{
      margin-top: 100px;
    }
  }
}   
.publishInfoBox{
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 205px;
  background-color: #D5FAF3;
  img{
    width: 100%;
    height: 100%;
    outline-style: none;
  }
}
</style>