<template>
  <div class="footerBox">
    <div class="footerContent">
      <div class="footerTop">
        <h2 class="topContent"><span>孩子学习成长</span><span class="lastText">恪度一路相伴</span></h2>
      </div>
      <div class="footerMain">
        <div class="leftFooter">
          <div class="footerIcon">
            <img class="headerIcon" src="@/assets/images/common/headerLeft.png">
          </div>
          <div class="linkUs">联系我们</div>
          
          <div class="emailAddress">
            <!-- <span class="emailIcon">@</span> -->
            <img class="emailIcon" src="@/assets/images/common/email.png">
            <!-- <img class="addressIcon" src="@/assets/images/common/address.png"> -->
            <span>hi@kedu.link</span>
          </div>
          <div class="footerAddress">
            <img class="addressIcon" src="@/assets/images/common/address.png">
            <span>北京市海淀区花园北路14号环星大厦E座202</span>
          </div>
        </div>
        <!-- <div class="middleFooter">
          <p class="leftLink">
            <span @click="goOtherPath('serviceSupport')">服务支持</span>
            <span @click="goOtherPath('feedBack')">提交反馈</span>
            <span @click="goOtherPath('aboutUs')">关于我们</span>
          </p>
          <p class="rightLink">
            <span @click="goOtherPath('product')">产品</span>
            <span @click="goOtherPath('dynamicInfo')">社会动态</span>
            <span @click="goOtherPath('viewPoint')">恪度观点</span>
          </p>
        </div> -->
        <div class="rightFooter">
          <div class="downIcon">
            <img class="appIcon" src="@/assets/images/common/douyin.png"/>
            <div class="labelText">恪度官方抖音号</div>
          </div>
          <div class="downIcon">
            <img class="appIcon" src="@/assets/images/common/webchart.png"/>
            <div class="labelText">恪度微信公众号</div>
          </div>
          <div class="downIcon">
            <img class="appIcon" src="@/assets/images/common/appClient.png"/>
            <div class="labelText">恪度小程序</div>
          </div>
          <div class="downIcon">
            <img class="appIcon" src="@/assets/images/common/appDown.png"/>
            <div class="labelText">下载恪度APP</div>
          </div>
          <!-- <div class="downIcon">
            <div class="weChatIcon"></div>
            <div class="labelText">恪度公众号</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="footerBottom">
      <span>Copyright©2020 北京恪度科技有限公司版权所有</span>
      <span class="rightText rightCursor" @click="goCheckPage">京ICP备2020043602号</span>
      <span class="rightText">京公网安备 11010802033801号</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'footer',
    props: {
      msg: String
    },
    methods:{
      goOtherPath(path) {
        this.$router.push(`/${path}`);
      },
      goCheckPage(){
         window.open(`https://beian.miit.gov.cn`, "_blank");
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .footerBox {
    width: 100%;
    height: 492px;
    // background: linear-gradient(360deg, #97FEE5 0%, #E7FBF6 100%) #F5F9F9;
    background: url("../assets/images/common/footerBg.png") no-repeat center 100%;
    /*opacity: 0.8;*/
    .footerContent{  
      margin: 0 auto;
      padding-left: 208px;
      padding-right: 210px;
      width: 1440px;
      height: 100%;
      // background: #F5F9F9;
      // background: linear-gradient(360deg, #97FEE5 0%, #E7FBF6 100%) #F5F9F9;  
      opacity: 0.8;
      .footerTop{
        padding-top: 76px;
        width: 100%;
        .topContent{
          margin: 0 auto;
          width: 821px;
          height: 70px;
          line-height: 70px;
          text-align: center;
          span{
            font-size: 50px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .lastText{
            margin-left: 50px;
          }
        }
      }
      .footerMain{
        padding-top: 113px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 346px;
        .leftFooter{
          padding-top: 15px;
          .footerIcon{
            display: flex;
            justify-content: flex-start;
            width: 300px;
            line-height: 33px;
            .headerIcon {
              width: 264px;
              height: 32px;
              outline-style: none;
            }
            .leftLine {
              margin: 6px 20px 0 35px;
              display: inline-block;
              width: 1px;
              height: 20px;   
              background: #28BFAF;
            }
            .sloganIcon {
              margin-top: 7px;
              width: 157px;
              height: 16px;
              outline-style: none;
            }
          }
          .linkUs{
            margin-top: 24px;
            width: 100%;
            height: 22px;
            line-height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #555555;
          }
          .emailAddress {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            width: 100%;
            height: 20px;
            line-height: 20px;
            .emailIcon {
              margin-left: 0px;
              margin-top: 2px;
              width: 16px;
              height: 16px;
              outline-style: none;
            }
            span {
              margin-left: 10px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC, MicrosoftYaHei;
              font-weight: 400;
              color: #555555;
            }
          }
          .footerAddress {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            width: 100%;
            height: 20px;
            line-height: 20px;
            .addressIcon {
              width: 16px;
              height: 16px;
              outline-style: none;
            }
            span {
              margin-left: 10px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC, MicrosoftYaHei;
              font-weight: 400;
              color: #555555;
            }
          }
        }
        .middleFooter{
          display: flex;
          justify-content: space-between;
          width: 141px;
          height: 120px;
          .leftLink{
            width: 56px;
            height: 120px;
            line-height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            span{
              display: inline-block;
              cursor: pointer;
            }
          }
          .rightLink{
            width: 56px;
            height: 120px;
            line-height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            span{
              display: inline-block;
              cursor: pointer;
            }
          }

        }
        .rightFooter{
          flex: 1;
          padding-top: 42px;
          padding-left: 210px;
          display: flex;
          justify-content: space-between;
          .downIcon{
            width: 100px;
            border-radius: 4px;
            .appIcon, .weChatIcon{
              margin-left: 10px;
              width: 80px;
              height: 80px;
              border-radius: 4px;
            }
            .labelText{
              margin-top: 2px;
              width: 100px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555555;
            }
          }
        }
      }
    }
    .footerBottom{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 22px;
      padding-bottom: 19px;
      width: 100%;
      min-width: 1440px;
      height: 58px;
      line-height: 17px;
      border-top: 1px solid #E6E6E6;
      span{
        line-height: 17px;
        font-size: 12px;
        font-family: Lato-Regular, Lato;
        font-weight: 400;
        color: #A29E9E;
      }
      .rightText{
        margin-left: 7px;
      }
      .rightCursor{
        cursor: pointer;
      }
    }
  }
</style>