import pointImg1 from '@/assets/images/viewPoint/pointImg1.png';
import pointImg2 from '@/assets/images/viewPoint/pointImg2.png';
import pointImg3 from '@/assets/images/viewPoint/pointImg3.png';
import pointImg4 from '@/assets/images/viewPoint/pointImg4.png';
import dynamicImg1 from '@/assets/images/dynamicInfo/dynamicImg1.png';
import dynamicImg2 from '@/assets/images/dynamicInfo/dynamicImg2.png';
import dynamicImg3 from '@/assets/images/dynamicInfo/dynamicImg3.png';
import dynamicImg4 from '@/assets/images/dynamicInfo/dynamicImg4.png';
import dynamicImg5 from '@/assets/images/dynamicInfo/dynamicImg5.png';
import dynamicImg6 from '@/assets/images/dynamicInfo/dynamicImg6.png';
import dynamicImg7 from '@/assets/images/dynamicInfo/dynamicImg7.png';
import dynamicImg8 from '@/assets/images/dynamicInfo/dynamicImg8.png';
import dynamicImg9 from '@/assets/images/dynamicInfo/dynamicImg9.png';

import content1 from '@/assets/images/dynamicInfo/content1.png';
import content2 from '@/assets/images/dynamicInfo/content2.png';
import content3 from '@/assets/images/dynamicInfo/content3.png';
import content4 from '@/assets/images/dynamicInfo/content4.png';
import content5 from '@/assets/images/dynamicInfo/content5.png';
import content6 from '@/assets/images/dynamicInfo/content6.png';
import content7 from '@/assets/images/dynamicInfo/content7.png';
import content8 from '@/assets/images/dynamicInfo/content8.png';
import content9 from '@/assets/images/dynamicInfo/content9.png';
import content10 from '@/assets/images/dynamicInfo/content10.png';
import content11 from '@/assets/images/dynamicInfo/content11.png';
import content12 from '@/assets/images/dynamicInfo/content12.png';
import content13 from '@/assets/images/dynamicInfo/content13.png';
import content14 from '@/assets/images/dynamicInfo/content14.png';
import content15 from '@/assets/images/dynamicInfo/content15.png';
import content16 from '@/assets/images/dynamicInfo/content16.png';

import homeSocietyImg1 from '@/assets/images/home/societyImg1.png';
import homeSocietyImg2 from '@/assets/images/home/societyImg2.png';
import homeSocietyImg3 from '@/assets/images/home/societyImg3.png';

export const viewPointData =  {
    total: 4,
    list: [
        {
            id: "1587",
            title: "不同年龄段的孩子，应该如何管理上网行为？",
            time: "2021-02-01",
            imgSrc: pointImg1,
            subTitle: "据2020年《中国未成年人互联网运用报告》，中国3岁孩子接触手机的比例达到70%。6岁孩子每天使用网络超过1小时的占比达到95%。可以看到，孩子上网越来越低龄化，管理孩子上网，刻不容缓，但是对于不同年龄段的孩子，管理的方式不尽相同。",
            detail: '<p style="text-indent:2em;">据2020年《中国未成年人互联网运用报告》，中国3岁孩子接触手机的比例达到70%。6岁孩子每天使用网络超过1小时的占比达到95%。可以看到，孩子上网越来越低龄化，管理孩子上网，刻不容缓，但是对于不同年龄段的孩子，管理的方式不尽相同。</p><br/>'
             + '<p style="text-indent:2em;">对于低龄孩子，我们管理他们上网相对简单，因为孩子使用网络时，家长通常和他在一起，很容易控制孩子访问的网络内容和上网时间。</p><br/>'
             + '<p style="text-indent:2em;">但是孩子上学以后，情况就有所不同了。随着在线教育的快速普及，上网已经不仅仅是一种娱乐方式，网络已经成为孩子们课内、课外获取知识的重要渠道。随着年龄的增长，他们上网课、交作业、上网查资料、和同学沟通交流，越来越需要网络的辅助。家长又无法一直陪在他身边。很多孩子在使用网络辅助学习时，忍不住会去刷抖音，打游戏，有的孩子甚至以学习为名要来手机，实际是偷偷上网娱乐。长此以往，必然养成不好的习惯。这个时候，就要加强对网络的管理了。</p>'
        },
        {
            id: "1588",
            title: "应该让孩子自己管理上网行为吗？",
            time: "2021-03-01",
            imgSrc: pointImg2,
            subTitle:"培养孩子自制力，是否应该让孩子自己管理自己？",
            detail: '<p style="text-indent:2em;">培养孩子自制力，是否应该让孩子自己管理自己？</p>'
            + '<p style="text-indent:2em;">我们认为，这点在上网这个问题上，对于绝大多数孩子是行不通的。青少年的心理和生理发育都还不成熟，孩子在面对网络上游戏、小说、音乐、社交等纷繁的诱惑，很难克制住自己。</p><br/>'
            + '<p style="text-indent:2em;">即便有的孩子有很强的毅力，但是在对抗网络诱惑的过程中，也消耗了大量的意志力，从而对学习产生不利的影响。</p><br/>'
            + '<p style="text-indent:2em;">从孟母三迁的故事我们都知道环境对于孩子成长的重要性，那么在网络的问题上我们为什么要选择拿那些不健康的东西来诱惑孩子，而不是为他建立一个良好的环境，从而让他在这个环境里培养好的习惯呢？</p>'
        },
        {
            id: "1589",
            title: "孩子上网，但不沉迷，还需要管理吗？",
            time: "2021-04-01",
            imgSrc: pointImg3,
            subTitle:"在2018年9月25日国家卫生健康委召开的新闻发布会上，中国科学院院士、北京大学第六医院院长陆林介绍，据统计，我国青少年过度依赖网络的发病率接近10%，他呼吁尽快制定官方的诊疗规范。那么对于大多数没有沉迷网络的孩子，是不是可以不用刻意管理他上网？",
            detail: '<p style="text-indent:2em;">在2018年9月25日国家卫生健康委召开的新闻发布会上，中国科学院院士、北京大学第六医院院长陆林介绍，据统计，我国青少年过度依赖网络的发病率接近10%，他呼吁尽快制定官方的诊疗规范。那么对于大多数没有沉迷网络的孩子，是不是可以不用刻意管理他上网？</p><br/>'
            + '<p style="text-indent:2em;">其实，对于上网但尚未沉迷的孩子，现在是管理他上网的最佳时间。为什么这么说呢？</p><br/>'
            + '<p style="text-indent:2em;">因为这个阶段的孩子可以理性地和家长沟通。每个孩子都有一个向善的愿望，只要家长加以引导，他是能够接受在学习的时间不上网娱乐这个观点的。那么接下来就是使用恪度帮助他管理住自己。为什么一定要借助一个工具呢? 因为作为一个孩子，尽管他知道什么是正确的行为，但是打开作业本，可能脑子里萦绕的还是刚才那个刺激的游戏画面。面对诱惑，难免心思有些活泛，但当他尝试打开游戏网站时，恪度就会告诉他，现在是作业时间，不能玩游戏。几次下来，相信孩子也就不会尝试去触犯规则了。只要坚持使用恪度一段时间，孩子慢慢就形成了良好的学习习惯，学习效率提升，成绩提升就成了自然的结果，而专注力、自律性这些牛娃们的标签也离咱家孩子不远了。</p><br/>'
            + '<p style="text-indent:2em;">相反，如果孩子已经沉迷网络，就需要家长付出更多心血与孩子沟通。但此时的孩子，往往正处在青春期，沟通难度很大。以往的经验告诉我们，这样孩子的家长通常开始对恪度寄予厚望。以为用了恪度，孩子就会远离网络。事实上，当确实被限制了上网后，孩子往往难以保持理智，情绪暴躁，和父母大闹，有的甚至以弃学、离家出走相逼。最后相当一部分家长的选择是放弃使用恪度，同时陷入更深的焦虑。此时，只有系统的医学诊疗和家长更大的付出才能帮助孩子摆脱对网络的依赖。</p><br/>'
            + '<p style="text-indent:2em;">这也是为什么我们坚持对孩子上网的管理要从低龄开始、从他还没有沉迷网络开始的原因。</p>'
        },
        {
            id: "1590",
            title: "用了恪度，会让父母和孩子的关系变得更糟吗？",
            time: "2021-05-01",
            imgSrc: pointImg4,
            subTitle:"使用恪度管理孩子上网，是否会让亲子关系更糟，家里气氛更紧张呢？这是很多家长担心的问题。",
            detail: '<p style="text-indent:2em;">使用恪度管理孩子上网，是否会让亲子关系更糟，家里气氛更紧张呢？这是很多家长担心的问题。</p>'
            + '<p style="text-indent:2em;">首先要说明的是，恪度绝不仅仅是一个管理孩子上网的工具，它更是一个帮助孩子遵守规则、信守承诺的工具。我们认为，引导孩子健康合理使用网络，最重要的不是管住他，而是培养他的规则意识，和孩子共同建立上网规则：学习时专心学习，娱乐要遵守时间约定，不主动接触网络上不健康的内容。建立规则之后，还必须要借助可靠的工具来实施有效监督。坚持做下去，必然有助于他提升专注力，养成好习惯。</p><br/>'
            + '<p style="text-indent:2em;">恪度就是这样一个帮助家长监督孩子是否遵守规则的工具，没有恪度的帮助，失去监管，规则必然流于形式，好习惯也化为泡影。但恪度也仅仅是个工具，它绝不能代替家长和孩子的之间沟通和引导。简单粗暴的管理方式必然招来孩子的反感。随着孩子慢慢长大，他越来越需要尊重，如果在不知情的情况下被限制了上网，同时还感受到了来自父母的极端不信任，这个时候，他表现得脾气暴躁、和家长强烈对抗也就不足为奇了。</p><br/>'
            + '<p style="text-indent:2em;">每一个孩子都是一个拥有独立人格的个体，只有在充分尊重孩子、充分沟通的基础上建立起来的规则和秩序。才能获得孩子的认同和遵循。使用恪度之前，一定要先和孩子共同制定上网规则，否则，真有可能让亲子关系更糟。</p>'
        }

    ]
};
export const dynamicInfoData= {
    total: 10,
    list: [
        {
            id: "9125",
            title: "我国青少年过度依赖网络发病率接近10%，相关诊疗规范正在制定",
            time: "2018-09-25",
            imgSrc: dynamicImg1,
            hommeImgSrc: homeSocietyImg1,
            firstTitle: '在国家卫生健康委25日召开的新闻发布会上，中国科学院院士、北京大学第六医院院长陆林介绍，据...',
            subTitle: "在国家卫生健康委25日召开的新闻发布会上，中国科学院院士、北京大学第六医院院长陆林介绍，据统计，全世界范围内青少年过度依赖网络的发病率是6%，我国的发病率稍高，已接近10%左右。目前，我国尚没有关于这一领域的官方诊疗规范，但正在制定之中。",
            sourceName: "光明网",
            detail: '<p style="text-indent:2em;">在国家卫生健康委25日召开的新闻发布会上，中国科学院院士、北京大学第六医院院长陆林介绍，据统计，全世界范围内青少年过度依赖网络的发病率是6%，我国的发病率稍高，已接近10%左右。目前，我国尚没有关于这一领域的官方诊疗规范，但正在制定之中。</p>'
            + '<p style="text-indent:2em;">陆林介绍说，在世界卫生组织2018年6月发布的第11版《国际疾病分类》中，游戏障碍被归入精神疾病的范畴。对此，医学界也有一些不同意见。他表示，世界卫生组织的初衷是希望社会能够更加重视和关注这个问题，籍此提供更多的帮助，甚至在青少年治疗时能得到保险方面的支持，所以此举的意义更多还是帮助青少年减少对网络的过度使用。</p>'
            +'<p style="text-indent:2em;">他指出，网络依赖只是一个现象，约有一半的孩子在过度依赖网络的背后，会伴随其他的问题，比如与父母关系问题、学业问题、注意缺陷障碍、焦虑或抑郁等。目前，对于青少年的焦虑、抑郁等其他精神心理问题，都有诊疗规范。但是，因为医学具有科学性和严谨性，网络成瘾涉及比较复杂的精神心理问题，要经过长时间的讨论和认识，才能形成诊疗规范。</p>'
            +'<p style="text-indent:2em;">他呼吁，让青少年从对网络的过度依赖中分离出来，还需要家庭和学校提供一个正常的学习和生活环境，把孩子的时间、精力、兴趣、爱好从对网络的过度依赖中分离出来，使其回归社会。同时，青少年一旦出现沉迷网络的念头时，要反复暗示自己或者寻求帮助，加强正常的人际交往，积极参加体育锻炼或者加入社团参加感兴趣的活动，来摆脱对网络的过度依赖。（光明日报融媒体记者金振娅）</p>'        
        },
        {
            id: "9126",
            title: "预防青少年网络沉迷刻不容缓",
            time: "2018-09-28",
            imgSrc: dynamicImg2,
            hommeImgSrc: homeSocietyImg2,
            firstTitle:"12岁小学生打赏网络主播，花掉环卫工母亲4万元积蓄；一青少年沉迷互联网赌博，走上盗窃之路...",
            subTitle:"12岁小学生打赏网络主播，花掉环卫工母亲4万元积蓄；一青少年沉迷互联网赌博，走上盗窃之路；13岁少年沉迷某款网络游戏，疑似模仿游戏中翻墙跳楼动作而身亡……近段时间来出现的一些相关案件，背后都有网络沉迷的因素，这提醒人们：预防青少年沉迷网络，刻不容缓。",
            sourceName: "光明网",
            detail: '<p style="text-indent:2em;">12岁小学生打赏网络主播，花掉环卫工母亲4万元积蓄；一青少年沉迷互联网赌博，走上盗窃之路；13岁少年沉迷某款网络游戏，疑似模仿游戏中翻墙跳楼动作而身亡……近段时间来出现的一些相关案件，背后都有网络沉迷的因素，这提醒人们：预防青少年沉迷网络，刻不容缓。</p>'
            + '<p style="text-indent:2em;">去年底的一项调查数据显示，被调查未成年人互联网总体普及率高达98.1%。与之相伴的是，网络成瘾现象十分严峻。青少年处于成长发育的关键时期，沉迷游戏、过度用网消耗了精力、耽误了学业，进而导致精神颓废、身体羸弱；网络信息泥沙俱下，孩子辨别能力不够，难免造成价值观走偏、行为失范，甚至诱发盗窃、暴力等违法行为……凡此种种，不仅损害了青少年的身心健康，更会对家庭、社会乃至国家长远发展带来巨大危害。世界卫生组织也于今年6月份，将网络游戏成瘾列入精神疾病。防止青少年沉迷网络，不仅是一个公共卫生课题，也是一项社会治理挑战。</p>'
            + '<p style="text-indent:2em;">客观来说，青少年沉迷网络有着复杂的原因。好奇心强、自制力弱、渴望得到认同等心理特点是一方面，规范管理、合理引导、预防治疗不够也是重要原因。其中，一些企业诱导青少年网络成瘾的做法难辞其咎。就拿网络游戏来说，一些游戏开发商为了赚孩子的钱，专门研发门槛低、互动性强、奖励诱惑多的网游；为了把少年儿童培养成“忠实玩家”，新角色、新道具、新玩法层出不穷；更有甚者在游戏中添加淫秽、赌博的成分，引诱腐蚀自制力不强的青少年。再比如一些网络视频平台，不仅缺乏针对青少年的内容分级，更是通过“算法推荐”“奖赏反馈”等成瘾机制的设计，让一些青少年“根本停不下来”。这些诱导成瘾的企业行为，需要得到有效的治理。</p>'
            + '<p style="text-indent:2em;">为了解决未成年人网络沉迷问题，近年来，国家多次出台规定。去年底印发的《关于严格规范网络游戏市场管理的意见》，对网络游戏违法违规行为和不良内容进行集中整治；前不久教育部等八部门联合印发《综合防控儿童青少年近视实施方案》，要求“控制电子产品使用”“实施网络游戏总量调控”。重拳整治网络空间秩序、规范青少年网络使用，体现了管理部门的决心。但就目前来看，面对屡见不鲜的诱导青少年网络成瘾行为、不断发展的互联网产品形态，监管的提升仍然有较大空间。如何建立未成年人网络保护的体制机制？如何加强对网络游戏、视频等产品的监管审核？如何利用技术手段把好防范网络沉迷的关口？这些需要相关部门抓紧论证、出台切实可行的办法。</p>'
            + '<p style="text-indent:2em;">当然，解决青少年网络成瘾这个难题，单靠管理部门的努力远远不够。对于企业来说，追求利益无可厚非，但唯利是图无法成为令人尊重的企业，漠视责任终究会受到法律严惩。当务之急是要把防沉迷措施建立起来，把社会责任落实到位。另一方面，预防青少年沉迷网络，家庭和学校的教育引导不能缺席。强制命令、一味禁止容易激发逆反心理，关键是帮助孩子做到健康上网、正确用网。多方发力、齐抓共管，方能让青少年远离网络沉迷的陷阱</p>'
        },
        {
            id: "9127",
            title: "孩子沉迷于网络游戏 家长一味施高压效果未必佳",
            time: "2018-09-27",
            imgSrc: dynamicImg3,
            hommeImgSrc: homeSocietyImg3,
            titleInfo: '一味施高压 效果未必佳(民生观)',
            firstTitle:"管孩子不是钉钉子，只管敲就行。钉子没自我意识，孩子可有自己的主意, 孩子沉迷于网络游戏...",
            subTitle:"管孩子不是钉钉子，只管敲就行。钉子没自我意识，孩子可有自己的主意, 孩子沉迷于网络游戏，茶不思饭不想，耽误了学习、损害了身体——相信但凡是做家长的，谁也不想这样的情况出现。可是，光有愿望没用，关键在于怎样做才有效。",
            sourceName: "央广网",
            detail: '<p style="text-indent:2em;">实话实说，不少家长的做法，并不能实现帮孩子远离网络沉迷的目标。</p>'
            +'<p style="text-indent:2em;">有的家长轻信一些来历可疑、没有正规资质的戒网瘾中心，把孩子送了进去，发生过不少悲剧。让人联想起《笑林广记》里头说的黑色笑话：为了治驼背，拿俩木板夹着病人使劲踩。“医生”还振振有词：我治的是罗锅，还管人死不死？</p>'
            +'<p style="text-indent:2em;">更多的家长是自己上马，劳心费力，没少折腾，但是效果总不令人满意。和一些困惑的家长交流后，我梳理了一下他们的管理过程，往往是这样：家长发现苗头，马上陷入焦虑，采取高压政策：全面禁网禁手机！然而，互联网社会里，维系这样一个全方位的“真空”状态非常困难，自己加班、爷爷奶奶心软、小伙伴互通有无……不知哪个环节有漏洞，就使管理变成旷日持久的猫鼠游戏。</p>'
            +'<p style="text-indent:2em;">管孩子不是钉钉子，只管敲就行。钉子没自我意识，孩子可有自己的主意。尤其在青春期，这是建立自我意识的关键时期，逆反心理强烈。家长一坚决反对，孩子反而觉得，不玩是听家长的，玩是“做自己”，对自主的渴求压倒了自制，事情于是棘手。</p>'
            +'<p style="text-indent:2em;">管理要想有效，就不能忽视孩子的心理特点。先要取信于子女，别扣道德帽子。然后，靶向治理，精准施策。与其全面禁网，不如摸清楚，到底哪一款游戏最让孩子沉迷、沉迷点在什么地方，进行重点管理。和孩子一起认识危害、讨论办法，让孩子自己拿出防沉迷举措，家长督促落实，尊重孩子的自主心理。孩子假如给自己留了继续玩游戏的空间，只要保证可控制、有分寸，比如完成作业玩半个小时，可以开个小口，毕竟危害在于丧失自制。另外，“自然界讨厌真空”，抽走网络游戏这一块，就要考虑填上什么。踢球、遛狗、家庭名著读书会……只有另一种有意思，才能替代一种有意思。</p>'
            +'<p style="text-indent:2em;">防止沉迷，也不能光让家长使劲。制定更精准的政策、有效识别青少年、不设“玩得越长奖励越多”式的激励、定时强制下线……这些措施，期待游戏公司和管理部门的继续发力。(李智勇)</p>'
        },
        {
            id: "9128",
            title: "青少年接触网络不良信息超七成含淫秽色情",
            time: "2017-01-05",
            imgSrc: dynamicImg4,
            hommeImgSrc: homeSocietyImg3,
            titleInfo: '《2016互联网不良信息对青少年危害分析年度报告》发布',
            subTitle:"2016年，随着“徐玉玉”、校园贷裸条风波等事件曝光，网络安全问题被推上风口浪尖。青少年作为网民的主力军，正不断遭受互联网上大量色情、暴力信息等网络不良信息侵扰。近日，共青团中央网络影视中心、猎网平台、中青奇未联合发布的《2016互联网不良信息对青少年危害分析年度报告》（下称报告）显示，淫秽色情信息仍为青少年接触不良信息的主要形式，在被举报的不良信息中超过半数源自...",
            sourceName: "人民网",
            detail: '<p style="text-indent:2em;">2016年，随着“徐玉玉”、校园贷裸条风波等事件曝光，网络安全问题被推上风口浪尖。青少年作为网民的主力军，正不断遭受互联网上大量色情、暴力信息等网络不良信息侵扰。近日，共青团中央网络影视中心、猎网平台、中青奇未联合发布的《2016互联网不良信息对青少年危害分析年度报告》（下称报告）显示，淫秽色情信息仍为青少年接触不良信息的主要形式，在被举报的不良信息中超过半数源自合法网站；而从被举报不良信息的网站中，超过30%来自搜索引擎。</p>'
            +'<p style="text-indent:2em;">淫秽色情信息仍为青少年接触不良信息主要形式</p>'
            +'<p style="text-indent:2em;">报告显示，截至2016年底，全国用户通过奇未安全桌面的举报插件举报各类互联网不良信息7000余条。从青少年用户的举报情况来看，淫秽色情信息是青少年最容易接触到的互联网不良信息，相关举报占到了举报总量的76.3%。此外，诈骗信息占比为8.5%，赌博信息占比为2.7%，其他各类不良信息占比为1.8%</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content1+'><p>'
            +'<p style="text-align:center;">淫秽色情为青少年接触不良信息主要形式</p>'
            +'<p style="text-indent:2em;">特别值得注意的一点是，在占比为8.5%的诈骗信息中，一半以上是混杂在淫秽色情信息中进行传播的。如虚假的同城交友、视频交友，色情直播平台等，其最终目的都是诈骗。</p>'
            +'<p style="text-indent:2em;">而随着网络技术日益普及化，一些被开发出来的新型网络活动也开始掺杂着淫秽信息。比如当下日益火爆的直播区域，情色主播的新闻屡见不鲜；在一些网络游戏上，很多游戏人物穿着暴露，动作不雅，游戏过程中更是充斥各种淫秽色情情节；甚至，还有5.4%的色情信息包含于各类新闻资讯中。</p>'
            +'<p style="text-indent:2em;">被举报不良信息超30%来自搜索引擎</p>'
            +'<p style="text-indent:2em;">令人不寒而栗的是，传播不良信息的网站并非都是非法网站。统计显示，在用户举报的涉嫌传播不良信息的网站中，若从举报信息的数量来看，企业网站被举报的次数占39.2%，个人网站占14.6%，无备案网站占46.1%。也就是说，按照被举报的不良信息数量统计，超过半数来自有合法备案的正规网站。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content2+'><p>'
            +'<p style="text-align:center;">传播不良信息网站备案情况分析</p>'
            +'<p style="text-indent:2em;">从境内备案网站进一步深入分析来看，在所有被举报涉嫌传播不良信息的网站中，搜索引擎以占比33.3%遥遥领先，其次为视频影视网站，占12.2%。另外，赫然在列的还有新闻门户网站，排名紧随在后，占比10.7%。</p>'
            +'<p style="text-indent:2em;">青少年网络安全教育工程特聘专家、猎网平台负责人裴智勇介绍，以用户举报的淫秽色情信息为例，约有5.4%的内容是各类新闻资讯，且这些资讯大多是由合法的正规媒体或网站发布。其主要特点是标题或正文存在不健康内容，资讯中插入不雅图片或胡乱引用淫秽图片。并且很多网站为吸引眼球，会把这些“花边”资讯放在首页推荐。“这些资讯对于成年人来说可能危害轻微，但对青少年的危害则十分明显。”</p>'
            +'<p style="text-indent:2em;">此外，从服务器的地域分布来看，在所有被举报的网站中，服务器在境内的占比为48.9%，服务器在境外的占比为51.1%。服务器在境内排名第一的是北京，其次是香港、广东。而服务器在境外排名第一的是美国，占比达88.6%</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content3+'><p>'
            +'<p style="text-align:center;">传播不良信息网站服务器地域分布</p>'
            +'<p style="text-indent:2em;">社交软件为青少年接触网络诈骗信息主要途径</p>'
            +'<p style="text-indent:2em;">除了大量的淫秽色情信息，诈骗信息同样在给青少年带去巨大的身心伤害。报告指出，社交软件是青少年接触诈骗信息的主要途径，占比约为56.7%；其次是游戏喊话占12.1%，电商网站占8.3%。而诈骗短信和诈骗电话则分别占6.2%和3.8%，二者之和仅占十分之一左右。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content4+'><p>'
            +'<p style="text-align:center;">社交软件为青少年接触诈骗信息主要途径</p>'
            +'<p style="text-indent:2em;">猎网平台的数据统计也显示，2016年1至11月，猎网平台共接到全国18岁以下青少年网络诈骗受害者举报1697人次，占猎网平台接到网络诈骗举报件总量的11.8%，人均损失达1845元，其中，金融理财类的网络诈骗更是高达6157元，对于没有经济能力的青少年而言，这个数字已经是一个高额数据。</p>'
            +'<p style="text-indent:2em;">网络诈骗，轻则使青少年受到经济损失，重则令受害者失去生命。今年8月份发生的“徐玉玉案件”就是一个血的教训，深受网络诈骗荼毒的不再只有成年人，同时还有青少年，他们已成为不法分子的 “首要猎物”。</p>'
            +'<p style="text-indent:2em;">青少年被骗人均损失女生明显高于男生</p>'
            +'<p style="text-indent:2em;">从性别差异上来看，在2016年1-11月猎网平台接到的青少年网络诈骗受害者的举报中，男生占比为56.4%，女生占比为43.6%，男生略多于女生。而从人均损失来看，女生为2085元，明显高于男生人均损失的1660元。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content5+'><p>'
            +'<p style="text-align:center;">青少年遭遇网络诈骗性别对比分析</p>'
            +'<p style="text-indent:2em;">报告显示，虽然虚假兼职同时是男生和女生被骗最多的诈骗类型，但在女生中占到34.9%，远高于男生的21.0%，这表明女生虽然更热衷于兼职赚钱，但防诈骗意识明显不高；而除了在虚假兼职类的诈骗中受骗较多以外，爱玩游戏的男生同样在游戏类的诈骗中受伤较深，18.7%的占比仅比虚假兼职低一点。</p>'
            +'<p style="text-indent:2em;">保护青少年上网安全是整个社会的责任</p>'
            +'<p style="text-indent:2em;">中国互联网络信息中心曾发布青少年上网行为研究报告称，我国青少年网民规模已达到2.87亿，据共青团中央和中青奇未公司联合此前发布的《2016互联网不良信息对青少年的危害分析白皮书》显示，有近80%的青少年通过电脑、手机等网络终端接触过不良信息，包括诈骗、色情、暴力等不良信息。</p>'
            +'<p style="text-indent:2em;">网络不良信息极其容易导致青少年遭受沉迷网络、价值观扭曲、网络诈骗等一系列上网安全问题，严重影响青少年健康成长，青少年网络安全问题，亟待解决。为保护我国青少年安全上网，国家网信办在去年公布了《未成年人网络保护条例（草案征求意见稿）》，规定保障未成年人平等、充分、合理地使用网络，保护未成年人免受网络违法信息侵害，避免未成年人接触不适宜其接触的信息，保护未成年人网上个人信息，防范未成年人沉迷网络。</p>'
            +'<p style="text-indent:2em;">不久前“传说中的女网警”高媛在接受采访中明确表示，避免在网络中遭受诈骗，首要得由网信、工信、支付机构和公安机关联手，进行根源性惩治。对于网民来说，必须教他们在网上遇到不确信的事儿就开口问，并在生活里养成不点击陌生网页，不相信陌生短信，不轻易向陌生网友、平台转账的良好习惯。因此，加强青少年的网络安全意识，首先要培养他们重视自身安全、遇到自己不确定的事儿及时求助的意识。</p>'
            +'<p style="text-indent:2em;">为响应国家青少年的网络保护工作，在共青团中央、中国少年先锋队全国工作委员会指导下，团中央网络影视中心和360公司共同发起青少工程校园安全教育公益项目。青少工程不仅在校内开设了网络完全教育团队课，将安全教育带进校园，还在校外建设了青少年网络安全教育科普基地，接受全国的青少年前来参观学习。此外，青少工程还开展了流动性更强的安全战车中国行活动，以4D、AR、语音识别等高科技的方式，向全国各地的青少年普及网络安全知识，提高安全意识。</p>'
            +'<p style="text-indent:2em;">此外，中青奇未公司还专门研发了保障青少年安全上网的奇未安全桌面。基于360海量的DNS解析数据，奇未桌面能帮助青少年有效拦截暴力、黄色等不良信息，为其安全成长保驾护航。</p>'
            +'<p style="text-indent:2em;">同时，针对此次报告中提到的所有被举报涉嫌传播不良信息网站中超过30%来自搜索引擎，中青奇未副总经理、青少年安全上网专家谢鹏认为，有必要研发推广专门适用于青少年使用的绿色搜索引擎，帮助青少年规避不良信息侵害。</p>'
            +'<p style="text-indent:2em;">“青少年是祖国的花朵，是社会主义建设的接班人，互联网环境中存在诸多风险和威胁，而网络又是一把双刃剑，对于青少年上网来说，有利又有弊。因此，让青少年安全上网、健康成长是社会各界人士共同努力的目标。”谢鹏表示。</p>'
        },
        {
            id: "9129",
            title: "上海调研未成年人网络犯罪现状及特征 不良行为者主要“沉迷网络”",
            time: "2020-09-21",
            imgSrc: dynamicImg5,
            hommeImgSrc: homeSocietyImg3,
            subTitle:"本报讯（中青报·中青网记者 王烨捷）9月18日，团上海市委、市委网信办、上海市青少年服务保护办公室正式启动“2020年国家网络安全宣传周上海地区青少年日主题活动”，并联合召开上海市青少年网络保护与预防犯罪工作研讨会。",
            sourceName: "中国青年报",
            detail: '<p style="text-indent:2em;">本报讯（中青报·中青网记者 王烨捷）9月18日，团上海市委、市委网信办、上海市青少年服务保护办公室正式启动“2020年国家网络安全宣传周上海地区青少年日主题活动”，并联合召开上海市青少年网络保护与预防犯罪工作研讨会。</p>'
            +'<p style="text-indent:2em;">活动现场，华东政法大学《青少年犯罪问题》编务主任、上海市预防青少年犯罪研究会副秘书长田相夏介绍了上海市未成年人网络犯罪的现状及特征。他介绍，上海市出现严重不良行为的未成年人中，52.28%的人上网主要是为了玩游戏。不良行为未成年人的主要不良行为包括沉迷网络或手机游戏、抄袭作业、打架辱骂他人、打扰课堂秩序，出现这些行为的占比分别为30.6%、25%、21.6%、20.6%。</p>'
            +'<p style="text-indent:2em;">根据上海方面的调研，当前未成年人网络犯罪案件呈现若干特征，如侵财型犯罪占主体地位，初中及以下学历犯罪者占比越来越高，新型犯罪手段越来越多，犯罪数额较大，共同犯罪特征明显等。</p>'
            +'<p style="text-indent:2em;">此外，成年人利用网络侵害未成年人权益的情况时有发生。成年人的涉案罪名相对集中，以性犯罪为主，犯罪方式越来越隐蔽，通过网络物色“猎物”和直接利用网络对未成年人实施犯罪的情况较多。</p>'
            +'<p style="text-indent:2em;">田相夏建议，应尽快推动未成年人网络保护法律法规的出台，强化和明确政府的监管职责。他还建议网信部门强化对互联网企业的监管，行业主管部门加强自治。“调研中我们发现，地方网信办只能对注册在本地的互联网企业进行监管，但给青少年带来不良影响的企业很有可能是外地企业。”对此，他认为主管部门应打破监管壁垒。</p>'
        },
        {
            id: "9130",
            title: "实名认证打折扣、打赏缺限制……如何做好青少年网络保护",
            time: "2021-03-22",
            imgSrc: dynamicImg6,
            hommeImgSrc: homeSocietyImg3,
            titleInfo: "防止网络沉迷 治理有害信息(来信与访谈·关注青少年上网环境(上))",
            subTitle:"在互联网上，金钱变成了一串数字，很容易使青少年出于跟风、炫耀等心理过度“打赏”而不自知；开启实名认证、识别青少年身份等规定与企业的商业利益产生冲突",
            sourceName: "腾讯新闻客户端",
            detail: '<p style="text-indent:2em;">在互联网上，金钱变成了一串数字，很容易使青少年出于跟风、炫耀等心理过度“打赏”而不自知；开启实名认证、识别青少年身份等规定与企业的商业利益产生冲突</p>'
            +'<p style="text-indent:2em;">新修订的未成年人保护法将于6月1日实施，其中未成年人“网络保护”单设一章，明确规定：网络产品和服务提供者不得向未成年人提供诱导其沉迷的产品和服务读者和专家建议，强化监管，督促互联网企业主动履行内容管理的主体责任，提供优质内容，对青少年模式的后台数据推送内容进行常态化运营；家长要对孩子多关心，多看管，加强兴趣引导</p>'
            +'<p style="text-indent:2em;">中国互联网络信息中心日前发布第四十七次《中国互联网络发展状况统计报告》，报告显示，截至2020年12月，我国网民人数已达9.89亿，10至19岁网民占比达13.5%，学生占比达21%。其中，处于学龄的青少年群体已经成为网民中比较庞大的群体之一。</p>'
            +'<p style="text-indent:2em;">互联网是青少年群体获取信息、认识世界的重要途径，但互联网上内容良莠不齐，很容易对心智尚未健全且自控能力较弱的青少年群体造成伤害。许多读者致信本报，反映当下青少年上网过程中出现的各种问题，呼吁相关方面形成治理合力，共同为青少年打造积极健康的上网环境。</p>'
            +'<p style="text-indent:2em;">青少年模式形同虚设，实名认证系统大打折扣</p>'
            +'<p style="text-indent:2em;">“从放寒假第一天起，孩子就天天捧着手机打游戏。”福建漳州市读者涂敏霞的孩子刚上初中，最近迷上了一款手机游戏，一玩就是好几个小时。这让涂敏霞忧心忡忡。</p>'
            +'<p style="text-indent:2em;">网络游戏受青少年欢迎，却让家长担心。据《中国互联网络发展状况统计报告》显示，截至2020年12月，我国网络游戏用户规模达5.18亿，占网民整体的52.4%；手机网络游戏用户规模达5.16亿，占手机网民的52.4%。青少年占了很大比例。</p>'
            +'<p style="text-indent:2em;">为防止青少年沉迷于网络游戏，有关部门要求网络游戏必须推出青少年模式，在使用时段、时长、功能、浏览内容等方面对青少年的上网行为进行限制和规范。但是，记者在调查中发现，有些网络游戏的青少年模式存在不少漏洞，实名制等规定也形同虚设。</p>'
            +'<p style="text-indent:2em;">涂敏霞说，为了防止孩子沉迷于网络游戏，她开启了青少年模式，并设置了4位数的密码，只有输入正确密码才能解除游戏的时长限制。但很快她就发现，孩子玩游戏的时间远远超过游戏声称的时长限制。原来，孩子在某电商平台，花几块钱购买了破解这款游戏青少年模式的教程，利用一些工具软件强行破解了密码，顺利解除了青少年模式的时长限制。</p>'
            +'<p style="text-indent:2em;">记者发现，除了破解密码，还有多种办法可以绕开青少年模式，比如时间到了先退出登录，再打开用游客模式玩，或者干脆卸载游戏，重新下载再安装等。更有甚者，有些网络游戏设置了VIP权限，只要充值一定额度，就能获得解除限制的特权。</p>'
            +'<p style="text-indent:2em;">除了青少年模式，相关部门还明确要求网络游戏必须实名注册，以达到甄别用户年龄的目的。但实际上，很多平台、游戏等都想方设法绕开这一规定，有的提供第三方账号登录方式规避实名制监管，有的虽然要求实名制注册，但只需输入身份证号码，用家长的身份证号码注册，甚至随意在网上找个身份证号码，就能顺利避开监管。</p>'
            +'<p style="text-indent:2em;">“有些网络游戏不仅不严格限制青少年注册，反而主动模糊青少年身份，有意无意地将青少年作为目标用户，诱使他们沉迷于网络游戏。”上海市读者陈晨表示，越来越庞大的青少年网民群体蕴含着巨大的商业利益，开启实名认证、识别青少年身份等规定实际上与企业的商业利益产生了冲突，所以一些游戏运营企业对推行实名认证系统态度并不积极。</p>'
            +'<p style="text-indent:2em;">直播内容没有监管，平台打赏缺乏限制</p>'
            +'<p style="text-indent:2em;">近年来，直播逐渐成为互联网行业的风口，吸引了大量网民观看，其中不乏青少年群体。但有些网络平台缺乏内容把控监管，为带有软色情、暴力等性质的直播大开方便之门，平台赚得盆满钵满，青少年群体却深受其害。</p>'
            +'<p style="text-indent:2em;">按照相关部门的要求，直播平台应当构建青少年专属内容池，提供适合青少年观看的内容。然而，记者在调查中发现，一些直播平台的青少年模式并未建设专属内容池，或者专属内容池出现同质化、劣质化等问题。记者随机打开一个下载量位居前列的手机直播软件，选择进入青少年模式，不久便刷到一位主播搔首弄姿，跳着带有色情意味的舞蹈，明显不适合青少年观看。此外，还出现暴力、恐怖等内容的视频直播，说明这款软件的青少年模式其实并没有进行直播内容上的筛选和甄别。</p>'
            +'<p style="text-indent:2em;">还有，一些不法分子利用网络直播诱骗青少年进行淫秽表演。全国“扫黄打非”办公室此前公布了一批案例。比如，在广东肇庆市公安局深入侦办的“口袋娱乐”直播平台案中，犯罪团伙组织诱骗未成年人进行淫秽表演，警方先后在15个省份抓获犯罪嫌疑人112名，解救未成年人36名，冻结和扣押资金1200余万元。</p>'
            +'<p style="text-indent:2em;">“对于网络直播中打色情‘擦边球’等问题，相关部门应该零容忍，依法予以坚决打击。”河北唐山市读者钟益辉认为，一方面要强化监管，督促互联网企业主动履行内容管理的主体责任，另一方面要深挖彻查，依法严惩违法犯罪分子。</p>'
            +'<p style="text-indent:2em;">“我家孩子14岁了，喜欢看一些自然、科技类的视频，但有些平台推送的内容制作粗糙、内容低劣，有的甚至还存在常识错误。”北京读者王兆晶表示，不同年龄段的青少年对内容需求存在很大差异，如果平台把一些错漏百出的视频推送给青少年观看，很可能对孩子的学习造成不良影响。</p>'
            +'<p style="text-indent:2em;">此外，还有不少读者对青少年观看直播时的“打赏”问题提出意见建议。陕西榆林市读者刘启光反映，有些直播平台的青少年模式仅限于观看直播页面，但用户仍可任意打赏，或者进入商城消费。“青少年往往对金钱缺乏具体概念，尤其是在网络上，金钱变成了一串数字，很容易使青少年出于跟风、炫耀等心理过度‘打赏’而不自知。”刘启光说，近年来，青少年打赏巨款的新闻屡见不鲜，除了进一步健全完善法律法规外，还应当对青少年使用支付功能采取一定限制，从源头上解决这一问题。</p>'
            +'<p style="text-indent:2em;">相关部门要精准施策，企业、家长也要各司其职</p>'
            +'<p style="text-indent:2em;">6月1日，新修订的未成年人保护法即将实施，其中未成年人“网络保护”单设一章，明确规定：网络产品和服务提供者不得向未成年人提供诱导其沉迷的产品和服务；网络游戏、网络直播、网络音视频、网络社交等网络服务提供者应当针对未成年人使用其服务设置相应的时间管理、权限管理、消费管理等功能。</p>'
            +'<p style="text-indent:2em;">“青少年专属内容池内容同质化、劣质化，意味着相应平台内容的种类有限，或者不符合青少年的需求，容易促使他们产生绕过青少年模式限制的想法。”北京市互联网金融行业协会党委书记许泽玮表示，为了让网络游戏、直播平台对青少年更加友好，互联网企业应该严格落实主体责任，一方面要遵守相关法律法规和监管部门规章，建立真正发挥限制作用的青少年模式，另一方面也要对青少年模式的后台数据、推送内容进行常态化运营，提供优质内容才能与用户形成良性互动，不能放任不管。</p>'
            +'<p style="text-indent:2em;">“相关部门除了加强监管，还要在青少年模式的实施细节上精准施策。”许泽玮认为，目前，对于适宜青少年观看内容的管理还有待加强，“究竟哪些内容是青少年应该看的，哪些是不应该看的；哪些适合13岁孩子，哪些适合15岁孩子，缺少明确界定。”</p>'
            +'<p style="text-indent:2em;">一些互联网企业反映，用户在进行游戏充值、直播“打赏”之前，都经过了实名认证，系统难以界定是否为实名认证本人的行为。对此，中国人民大学新闻学院副教授杨钢元建议引入人脸识别技术，任何用户进行支付消费前，均须开启人脸识别，只有实名认证信息和人脸识别相匹配时，方可进入下一步支付消费程序。</p>'
            +'<p style="text-indent:2em;">“即便每款软件的青少年模式都能有效运行，还会有新问题产生。比如，一款软件限时使用40分钟，但手机中多款软件叠加，青少年上网总时长仍无法控制，难以解决沉迷网络问题。”杨钢元建议，是否可以开发一款汇总手机中各类软件使用时长的软件，分析各软件实际使用信息，家长也可以事前设置总时长，一旦超时，还可以远程控制。</p>'
            +'<p style="text-indent:2em;">湖北武汉市读者李亢认为，很多家长或是客观上对互联网缺乏了解、不够适应，或者主观上没有引起足够重视，没能发挥监护人应尽义务、承担起责任，“家长要对孩子多一些关心，多一些看管，多一些兴趣引导，出现问题后不能一味归咎游戏、直播平台”。此外，学校也应该主动发挥作用，在相关课程中注重网络素质教育，帮助青少年正确理解并使用互联网。（史一棋）</p>'
        },
        {
            id: "9131",
            title: "三成未成年人曾网上接触色情等信息 背后黑色利益链曝光",
            time: "2019-04-13",
            imgSrc: dynamicImg7,
            hommeImgSrc: homeSocietyImg3,
            subTitle:"最新的研究报告显示，截至2018年7月31日，我国未成年人网民数量到达1.69亿，手机已经成为我国青少年上网的主要工具。然而青少年网络安全问题也日益凸显....。",
            sourceName: "中央电视台",
            detail: '<p style="text-indent:2em;">最新的研究报告显示，截至2018年7月31日，我国未成年人网民数量到达1.69亿，手机已经成为我国青少年上网的主要工具。然而青少年网络安全问题也日益凸显....。</p>'
            +'<p style="text-indent:2em;">在网络带来海量信息的同时，不良内容成为影响未成年人网络安全的主要隐患。研究报告显示，有15.6%的未成年人表示曾遭遇网络暴力，最常见的是网上讽刺或谩骂、自己或亲友在网上被恶意骚扰、个人信息在网上被公开。30.3%的未成年人曾在上网过程中接触到暴力、赌博、吸毒、色情等违法不良信息。</p>'
            +'<p style="text-indent:2em;">近日山东的李女士发现，自己正在就读小学的孩子经常玩的一款名为“我的世界”的网游界面上，充斥着大量不雅言语和色情交友信息。</p>'
            +'<p style="text-indent:2em;">色情信息充斥网游平台 家长举报难</p>'
            +'<p style="text-indent:2em;">小学生家长李女士：十条信息里面至少有两三条，我刻意拍的是针对某些年龄段的。招募信息上有明确的要求，男13岁以下，男14岁以下，内容非常不堪。</p>'
            +'<p style="text-indent:2em;">“我的世界”的游戏简介中标注称：“本游戏适合全年龄段玩家”，记者统计发现，最近18个月，仅在苹果应用商店中“我的世界”下载量就超过了2700万次。然而这个涵盖低龄段用户的网游中，色情交友内容却大行其道。李女士想把这些不良内容发布者的信息向平台举报，但是却发现没那么容易。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content6+'><p>'
            +'<p style="text-indent:2em;">小学生家长李女士：没有找到任何举报键，找了半天，举报它的发言功能完全都没有。</p>'
            +'<p style="text-indent:2em;">记者下载、登录了“我的世界”网游，在其“本地联机”的子版块中，记者见到一些玩家已经建好了联机房间等待其它人进入，其中包含大量用色情文字组合成的房间名称。</p>'
            +'<p style="text-indent:2em;">记者点击进入一个游戏房间，对方便发来“你几岁”的信息，而接着下来对方更是发来了QQ号，要求视频裸聊。而记者在这个聊天的界面和“本地联机”的子版块中都没有找到“举报”或者“投诉”的选项。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content7+'><p>'
            +'<p style="display: flex;"><img style="margin:20px auto 0;" src='+content8+'><p>'
            +'<p style="text-indent:2em;">记者：有没有举报两个字的关键词的提示，用户能直接点进去？</p>'
            +'<p style="text-indent:2em;">客服：目前客服这边无法查询到游戏里面是否有设置。</p>'
            +'<p style="text-indent:2em;">我国网络安全法规定，网络运营者应当建立网络信息安全投诉、举报制度，公布投诉、举报方式等信息，及时受理并处理有关网络信息安全的投诉和举报。但截至发稿前，记者发现，色情交友信息依旧在“我的世界”联机版块中大量存在。</p>'
            +'<p style="display: flex;"><img style="margin:20px auto 0;" src='+content9+'><p>'
            +'<p style="text-indent:2em;">中国政法大学传播法研究中心副主任朱巍：如果投诉以后，它写到投诉日志里去，那它就应当知道这种违法情况的存在，如果它没有及时屏蔽断开链接或删除的话，那它承担的责任就是一个共同侵权的责任，甚至是犯罪的共犯的责任。</p>'
            +'<p style="text-indent:2em;">不良内容渗入学习平台 典故遭恶改</p>'
            +'<p style="text-indent:2em;">色情信息泛滥，用户举报困难，面向全年龄段用户的网游，在网络安全上层层失守。而央视记者通过调查还发现，一些打着学习类名头的软件和微信公众号，因为管理缺位，也在放任不良的内容传播。 今年2月，记者登录了一款名为“作业狗”的学习类应用软件，在其有社交功能的“遛一遛”版块中，“醉校园”的栏目被放在了页面的中间，点击进入后记者见到一个名为“小可爱”的用户发布了多张色情图片。同时在这个版块中有多位用户发帖称想要寻找对象，并贴上了未成年人的图片和QQ号。记者发现，“作业狗”可以通过非实名的第三方账户进行登录，发帖不受任何限制。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content10+'><p>'
            +'<p style="display: flex;"><img style="margin:20px auto 0;" src='+content11+'><p>'
            +'<p style="text-indent:2em;">据记者调查，这款“作业狗”应用软件的运营商为深圳万利达教育电子有限公司。而在国家企业信用信息公示系统中记者发现，该公司已经在去年6月份进行了注销。运营者已经走人，面向青少年用户的学习类软件却沦为色情交友平台。</p>'
            +'<p style="text-indent:2em;">不法分子除了盯上没有人经营的学习类软件，还拿中小学生的学习内容上大做文章，向未成年人传播违背公序良俗的内容。记者在被冠以“学习类”的微信公众号“快作业”中见到了大肆宣扬升官发财、娶妻纳妾的网游广告。一款名为“当朝宰相”的网游，直接把中小学生课本里的经典故事“凿壁偷光”改成了“凿壁偷窥”，植入游戏剧情，并把这一桥段做成了视频广告，在多个网络平台投放。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content12+'><p>'
            +'<p style="text-indent:2em;">南京大学教育研究院副院长操太圣：如果对一些经典的内容歪曲的话，就是误导孩子，孩子可能会按照所谓的经典去模仿、去学习，而导致对孩子产生一些不良的影响。</p>'
            +'<p style="text-indent:2em;">无孔不入的不良网络信息，严重威胁着未成年人的网络健康环境。有法律学者建议，在《未成年人保护法》等法律法规中加入涉及未成年人相关的网络犯罪行为，并明确应从重处罚。</p>'
            +'<p style="text-indent:2em;">北京市青少年法律援助与研究中心研究员张雪梅：在网络保护当中一定要考虑到对儿童网络色情的问题，对儿童的网络性侵问题，要规定的任何组织和个人都不能通过网络以文字、图片、音视频等方式，去歪曲、损害、扭曲儿童的形象，以及去传播涉及儿童的一些色情制品。</p>'
            +'<p style="text-indent:2em;">加强保护未成年人网络安全！</p>'
        },
        {
            id: "9132",
            title: "男子为打发时间, 与10岁小女孩交友, 聊天内容不堪入目",
            time: "2019-04-02",
            imgSrc: dynamicImg8,
            hommeImgSrc: homeSocietyImg3,
            subTitle:"随着视频软件的诞生，有很多人在生活工作之余都会刷刷视频，以此来寻求开心和打发时间，而家住杭州的杜大哥却因为此时气愤极了，最后还报了警。那么杜大哥到底因为什么事情这样做的呢?",
            sourceName: "搜狐网",
            detail: '<p style="text-indent:2em;">随着视频软件的诞生，有很多人在生活工作之余都会刷刷视频，以此来寻求开心和打发时间，而家住杭州的杜大哥却因为此时气愤极了，最后还报了警。那么杜大哥到底因为什么事情这样做的呢?</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content13+'><p>'
            +'<p style="text-indent:2em;">杜大哥的女儿今年十岁了，为了女儿能够生活学习更方便，杜大哥给女儿配了一部手机，让她能够随时上网查资料，可杜大哥发现女儿拿到手机之后，迷上了一款视频软件，平时会录制一些小视频然后发到网上。</p>'
            +'<p style="text-indent:2em;">刚开始杜大哥也觉得这个软件十分有意思，可是，后来他发现女儿经常和一名陌生男子打电话，随后杜大哥查看了两个人的聊天记录，发现对方通过视频软件加了女儿的微信，两个人的聊天内容让人不堪入目，聊天内容十分打的露骨，明显涉嫌到猥亵未成年的行为，杜先生认为那名男子肯定是在网络上经常会扮演这种角色，去侵害青少年或者儿童。为了不让自己的女儿在受到荼毒，杜大哥把情况反应给了当地派出所，警方也已立案调查。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content14+'><p>'
            +'<p style="text-indent:2em;">记者通过微信号联系上了那名男子，了解到了对方有正常的工作，无聊的时候会打开视频软件看看视频，当被问到是否骚扰了一名十岁大的女孩子，男子就不吭声了，直接挂断了语音通话。随后，那名男子又通过微信发来了一条消息，说他以后会约束自己的行为。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content15+'><p>'
            +'<p style="text-indent:2em;">每个人都对自己的言行有所约束，但对于视频软件对未成年的保护措施，还需要完善。其实，平台不仅应该加强对未成年人的监管，也应该对平台内部发布的视频进行筛选检查，将有安全隐患的视频内容屏蔽掉，陕西西安的一名八岁男童就因模仿视频平台上的胶带粘门的整人视频，导致六岁的弟弟下巴摔伤，缝了数针。而对于平台整治方面，平台相关负责人也做出了回应，表示平台内日后会加强监管，持续努力把平台做成最安全最健康的短视频平台。</p>'
            +'<p style="display: flex;"><img style="margin:0 auto;" src='+content16+'><p>'
            +'<p style="text-indent:2em;">好在杜大哥的女孩没有受到侵害，但家长对于未成年的管教应该加强重视，避免孩子受到伤害。</p>'     
        },
        {
            id: "9133",
            title: "小伙交友软件结识12岁女孩 发生关系被判四年八个月",
            time: "2019-07-25",
            imgSrc: dynamicImg9,
            hommeImgSrc: homeSocietyImg3,
            subTitle:'小伙通过 "探探" 和 12 岁女孩结识，两人相约看电影时，小伙亲吻致女孩颈部产生吻痕，小伙以找衣服遮挡为由将女孩带到住处，趁机与女孩发生关系 …… 公诉机关以涉嫌强奸罪对小伙提起公诉，女孩则要求小伙赔偿 35 万元。',
            sourceName: "半岛晨报",
            detail: '<p style="text-indent:2em;">小伙通过 " 探探 " 和 12 岁女孩结识，两人相约看电影时，小伙亲吻致女孩颈部产生吻痕，小伙以找衣服遮挡为由将女孩带到住处，趁机与女孩发生关系 …… 公诉机关以涉嫌强奸罪对小伙提起公诉，女孩则要求小伙赔偿 35 万元。</p>'
            +'<p style="text-indent:2em;">小伙和 12 岁女孩发生性关系</p>'
            +'<p style="text-indent:2em;">2000 年出生的吕某住在大连瓦房店，2019 年 1 月 3 日 12 时许，吕某通过软件 " 探探 " 结识了 2006 年出生的女孩悦悦（化名），吕某也知道悦悦的年龄。</p>'
            +'<p style="text-indent:2em;">当天 19 时许，两人相约在瓦房店市大华商厦门口见面，并一同吃饭、看电影。看电影过程中，吕某用手摸悦悦胸部、亲吻悦悦嘴部和颈部，导致悦悦颈部留下吻痕，之后吕某以为悦悦找衣服遮挡吻痕为由，将悦悦带到瓦房店市大华后身其租住的房屋内，与悦悦发生了关系。</p>'
            +'<p style="text-indent:2em;">2019 年 1 月 4 日，吕某被公安机关抓获，其到案后如实供述案发经过。</p>'
            +'<p style="text-indent:2em;">2019 年 3 月 28 日，瓦房店市人民检察院以犯强奸罪对吕某提起公诉。悦悦提起附带民事诉讼，请求依法从重追究吕某的刑事责任，并判令吕某赔偿误工费、精神损害抚慰金等各项损失 35 万元。</p>'
            +'<p style="text-indent:2em;">吕某对公诉机关指控的事实无异议，并当庭认罪；对附带民事诉讼原告人悦悦的诉讼请求无异议。</p>'
            +'<p style="text-indent:2em;">犯强奸罪小伙被判四年八个月</p>'
            +'<p style="text-indent:2em;">辩护人认为，吕某有从轻或减轻处罚情节。首先，虽然吕某与悦悦发生性关系，但是吕某未采取暴力、胁迫、恫吓等手段，从事前、事中、事后的行为可以认定悦悦主观上是自愿的，在量刑上应有别于暴力强奸。其次，吕某具有自首情节，2019 年 1 月 4 日 2 时 41 分被害人的第一次报案笔录并未陈述其被强奸，吕某在 1 月 4 日 13 时 26 分的第一次笔录中主动供述其有强奸行为，所以应认定吕某自首，应对其从轻或减轻处罚。第三，吕某作案时刚刚年满 18 周岁，对法律知识了解甚少，法律知识淡薄，不知与未满十四周岁的幼女发生关系构成强奸罪，其主观恶性小，对社会危害性不大，恳请合议庭在量刑时对其从轻或减轻处罚。</p>'
            +'<p style="text-indent:2em;">法院审理认为，吕某明知悦悦不满十四周岁，仍与其发生关系，吕某的行为侵犯了悦悦的性自主权，构成强奸罪。吕某到案后如实供述案发经过，系坦白，予以从轻处罚。</p>'
            +'<p style="text-indent:2em;">本案系刑事附带民事诉讼，附带民事诉讼原告人悦悦要求吕某给付精神抚慰金的诉讼请求，于法无据，法院不予支持；悦悦要求吕某赔偿其他物质损失，因悦悦未提供证据证明，其应承担举证不能的不利后果，对该项请求，法院不予支持。</p>'
            +'<p style="text-indent:2em;">瓦房店市人民法院一审判决，吕某犯强奸罪，判处有期徒刑四年八个月；驳回附带民事诉讼原告人诉讼请求。</p>'
        }
    ]
}